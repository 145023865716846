import React from "react";
import { TbArrowsDiagonal } from "react-icons/tb";
import { FiLogOut } from "react-icons/fi";
import * as RiIcons from "react-icons/ri";
import * as FaIcons from "react-icons/fa";

const VoiceBotHeader = ({
  isInChat,
  handleBackClick,
  isLoggedIn,
  handleLogout,
  isExpandedWindow,
  handleExpand,
  toggleChat,
  isPending,
  config,
  customBranding,
  showChatHistory
}) => {
  return (
    <div
      className={`flex justify-between items-center p-3 ${
        isExpandedWindow ? "rounded-none" : "rounded-t-lg"
      }`}
      style={{
        backgroundColor: config.color,
        color: customBranding.headerTextColor,
      }}
    >
      {isInChat && (
        <button
          onClick={handleBackClick}
          className="p-1 rounded-full hover:bg-white hover:bg-opacity-20 transition-all duration-300"
          disabled={isPending}
        >
          <RiIcons.RiArrowLeftLine size={18} />
        </button>
      )}
      <h2
        className={`font-bold ${
          isExpandedWindow ? " text-2xl" : "text-lg"
        } `}
      >
        {showChatHistory ? "Chat History" : config.name}
      </h2>
      <div className="flex space-x-2">
        {isLoggedIn && (
          <button
            onClick={handleLogout}
            className="p-1 rounded-full hover:bg-white hover:bg-opacity-20 transition-all duration-300"
            title="Logout"
          >
            <FiLogOut size={18} />
          </button>
        )}
        {!isExpandedWindow && (
          <>
            <button
              onClick={handleExpand}
              className="p-1 rounded-full hover:bg-white hover:bg-opacity-20 transition-all duration-300"
              title="Open in new window"
            >
              <TbArrowsDiagonal size={18} />
            </button>
            <button
              onClick={toggleChat}
              className="p-1 rounded-full hover:bg-white hover:bg-opacity-20 transition-all duration-300"
              disabled={isPending}
            >
              <FaIcons.FaChevronDown size={18} />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default VoiceBotHeader;