import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FileText, CheckCircle, Loader2, Settings, X } from "lucide-react";
import axios from "axios";
import { toast } from "react-toastify";
import { apiEndpoints } from "../config/config";
import SignaturePad from 'react-signature-pad-wrapper';
// Add a GDPRBanner component
const GDPRBanner = ({ onAccept, onDeny, onManagePreferences }) => (
    <motion.div
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      exit={{ y: 100 }}
      className="fixed bottom-0 left-0 w-full bg-gray-900 text-white p-4 z-50"
    >
      <div className="max-w-7xl mx-auto flex flex-col sm:flex-row items-center justify-between gap-4">
        <p className="text-sm">
          This website uses cookies to improve your experience. See our{" "}
          <a href="/privacy-policy" className="text-cyan-400 underline">
            Privacy Policy
          </a>
          .
        </p>
        <div className="flex gap-2">
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={onAccept}
            className="px-4 py-2 bg-gradient-to-r from-cyan-600 to-cyan-700 rounded-lg text-sm"
          >
            Accept
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={onDeny}
            className="px-4 py-2 bg-gradient-to-r from-red-600 to-red-700 rounded-lg text-sm"
          >
            Deny
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={onManagePreferences}
            className="px-4 py-2 bg-gradient-to-r from-slate-600 to-slate-700 rounded-lg text-sm"
          >
            Manage Preferences
          </motion.button>
        </div>
      </div>
    </motion.div>
  );

  const PreferencesModal = ({ isOpen, onClose, onSave }) => {
    const [preferences, setPreferences] = useState({
      analytics: localStorage.getItem("analytics_cookies") === "true",
      marketing: localStorage.getItem("marketing_cookies") === "true"
    });
  
    if (!isOpen) return null;
  
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
      >
        <motion.div
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0.9 }}
          className="bg-white rounded-xl shadow-xl p-6 max-w-md w-full"
        >
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-bold text-gray-900 flex items-center gap-2">
              <Settings className="w-5 h-5" />
              Cookie Preferences
            </h3>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
  
          <div className="space-y-4">
            <div>
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked
                  disabled
                  className="rounded border-gray-300"
                />
                <span className="font-medium">Essential (Always On)</span>
              </label>
              <p className="text-sm text-gray-600 mt-1 ml-6">
                Required for the website to function properly.
              </p>
            </div>
  
            <div>
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={preferences.analytics}
                  onChange={(e) =>
                    setPreferences({ ...preferences, analytics: e.target.checked })
                  }
                  className="rounded border-gray-300"
                />
                <span className="font-medium">Analytics</span>
              </label>
              <p className="text-sm text-gray-600 mt-1 ml-6">
                Help us understand how you use our website.
              </p>
            </div>
  
            <div>
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={preferences.marketing}
                  onChange={(e) =>
                    setPreferences({ ...preferences, marketing: e.target.checked })
                  }
                  className="rounded border-gray-300"
                />
                <span className="font-medium">Marketing</span>
              </label>
              <p className="text-sm text-gray-600 mt-1 ml-6">
                Used for personalized recommendations.
              </p>
            </div>
          </div>
  
          <div className="flex justify-end gap-2 mt-6">
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => onSave(preferences)}
              className="px-4 py-2 bg-gradient-to-r from-cyan-600 to-slate-600 text-white rounded-lg text-sm"
            >
              Save Preferences
            </motion.button>
          </div>
        </motion.div>
      </motion.div>
    );
  };

const UserForm = () => {
    const [formData, setFormData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [formFields, setFormFields] = useState([]);
    const [responses, setResponses] = useState({});
    const [errors, setErrors] = useState({});
 const [showGDPR, setShowGDPR] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const [signaturePad, setSignaturePad] = useState(null);
  const [signatureData, setSignatureData] = useState(null);
  const [signatureError, setSignatureError] = useState('');
  const [parsedSettings, setParsedSettings] = useState({});

  useEffect(() => {
    const consent = localStorage.getItem("gdpr_consent");
    if (!consent || consent === "denied") {
      setShowGDPR(true);
    } else {
      // Hide banner if consent was accepted or customized
      setShowGDPR(false);
    }
  }, []);

  // const downloadSignatureAsPNG = () => {
  //   if (!signaturePad || signaturePad.isEmpty()) {
  //     toast.error("Please sign before downloading");
  //     return;
  //   }
  
  //   try {
  //     // Get the signature as a data URL with PNG format
  //     const dataURL = signaturePad.toDataURL('image/png');
      
  //     // Create a temporary link element
  //     const link = document.createElement('a');
  //     link.href = dataURL;
  //     link.download = 'signature.png';
      
  //     // Append to the document, click to trigger download, then remove
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
      
  //     toast.success("Signature downloaded successfully");
  //   } catch (error) {
  //     console.error("Error downloading signature:", error);
  //     toast.error("Failed to download signature");
  //   }
  // };

  // Most optimized download function using fromDataURL with options
// Fixed download function for react-signature-pad-wrapper
// const downloadSignatureAsPNG = () => {
//   if (!signaturePad || signaturePad.isEmpty()) {
//     toast.error("Please sign before downloading");
//     return;
//   }

//   try {
//     // Define the target dimensions
//     const targetWidth = 800;
//     const targetHeight = 400;
    
//     // Get the original signature as a data URL
//     const originalDataURL = signaturePad.toDataURL('image/png');
    
//     // Create a new canvas with the target dimensions
//     const canvas = document.createElement('canvas');
//     canvas.width = targetWidth;
//     canvas.height = targetHeight;
    
//     // Get the context and fill with white background
//     const ctx = canvas.getContext('2d');
//     ctx.fillStyle = 'white';
//     ctx.fillRect(0, 0, targetWidth, targetHeight);
    
//     // Create a new image object
//     const img = new Image();
    
//     // When the image loads, draw it on the temporary canvas
//     img.onload = function() {
//       // Calculate the scaling to fit within the target dimensions while preserving aspect ratio
//       const scale = Math.min(
//         (targetWidth * 0.8) / img.width,
//         (targetHeight * 0.8) / img.height
//       );
      
//       // Calculate centered position
//       const offsetX = (targetWidth - img.width * scale) / 2;
//       const offsetY = (targetHeight - img.height * scale) / 2;
      
//       // Draw the image centered and scaled
//       ctx.drawImage(
//         img,
//         0, 0, img.width, img.height,
//         offsetX, offsetY, img.width * scale, img.height * scale
//       );
      
//       // Add a subtle border
//       ctx.strokeStyle = '#f0f0f0';
//       ctx.lineWidth = 2;
//       ctx.strokeRect(0, 0, targetWidth, targetHeight);
      
//       // Get the data URL from the new canvas
//       const dataURL = canvas.toDataURL('image/png');
      
//       // Create a temporary link element
//       const link = document.createElement('a');
//       link.href = dataURL;
//       link.download = 'signature.png';
      
//       // Append to the document, click to trigger download, then remove
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
      
//       toast.success("Signature downloaded successfully");
//     };
    
//     // Set the source of the image to the signature data URL
//     img.src = originalDataURL;
//   } catch (error) {
//     console.error("Error downloading signature:", error);
//     toast.error("Failed to download signature");
//   }
// };

// Updated download function that respects the configured dimensions
const downloadSignatureAsPNG = () => {
  if (!signaturePad || signaturePad.isEmpty()) {
    toast.error("Please sign before downloading");
    return;
  }

  try {
    // Use the configured dimensions from settings or fallback to defaults
    const targetWidth = parsedSettings.signatureWidth || 400;
    const targetHeight = parsedSettings.signatureHeight || 200;
    
    // Get the original signature as a data URL
    const originalDataURL = signaturePad.toDataURL('image/png');
    
    // Create a new canvas with the target dimensions
    const canvas = document.createElement('canvas');
    canvas.width = targetWidth;
    canvas.height = targetHeight;
    
    // Get the context and fill with white background
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, targetWidth, targetHeight);
    
    // Create a new image object
    const img = new Image();
    
    // When the image loads, draw it on the temporary canvas
    img.onload = function() {
      // Calculate the scaling to fit within the target dimensions while preserving aspect ratio
      const scale = Math.min(
        (targetWidth * 0.8) / img.width,
        (targetHeight * 0.8) / img.height
      );
      
      // Calculate centered position
      const offsetX = (targetWidth - img.width * scale) / 2;
      const offsetY = (targetHeight - img.height * scale) / 2;
      
      // Draw the image centered and scaled
      ctx.drawImage(
        img,
        0, 0, img.width, img.height,
        offsetX, offsetY, img.width * scale, img.height * scale
      );
      
      // Add a subtle border
      ctx.strokeStyle = '#f0f0f0';
      ctx.lineWidth = 2;
      ctx.strokeRect(0, 0, targetWidth, targetHeight);
      
      // Get the data URL from the new canvas
      const dataURL = canvas.toDataURL('image/png');
      
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = dataURL;
      link.download = 'signature.png';
      
      // Append to the document, click to trigger download, then remove
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      toast.success("Signature downloaded successfully");
    };
    
    // Set the source of the image to the signature data URL
    img.src = originalDataURL;
  } catch (error) {
    console.error("Error downloading signature:", error);
    toast.error("Failed to download signature");
  }
};

const handleClearSignature = () => {
  if (signaturePad) {
    signaturePad.clear();
    setSignatureData(null);
    setSignatureError('');
  }
};

const validateSignature = () => {
  if (signaturePad?.isEmpty()) {
    setSignatureError('Signature is required');
    return false;
  }
  setSignatureError('');
  return true;
};

  const handleAcceptGDPR = () => {
    localStorage.setItem("gdpr_consent", "accepted");
    localStorage.setItem("analytics_cookies", "true");
    localStorage.setItem("marketing_cookies", "true");
    setShowGDPR(false);
  };

  const handleDenyGDPR = () => {
    localStorage.setItem("gdpr_consent", "denied");
    localStorage.setItem("analytics_cookies", "false");
    localStorage.setItem("marketing_cookies", "false");
    setShowGDPR(false);
  };

  const handleSavePreferences = (preferences) => {
    localStorage.setItem("analytics_cookies", preferences.analytics);
    localStorage.setItem("marketing_cookies", preferences.marketing);
    localStorage.setItem("gdpr_consent", "custom");
    setShowPreferences(false);
    setShowGDPR(false);
    toast.success("Cookie preferences saved!");
  };

    useEffect(() => {
        // UserForm.js
        const checkLoginAndFetchForm = async () => {
            try {
                const pathSegments = window.location.pathname.split("/");
                const formId = pathSegments[pathSegments.length - 1];

                if (!formId) {
                    toast.error("Missing form ID");
                    return;
                }

                const response = await axios.get(
                    `${apiEndpoints.form}/validate-form?formId=${formId}`
                );
                console.log("Full API Response:", response.data); // Check full response
                const formDataResponse = response.data.form;
                console.log("Form Settings:", formDataResponse.settings); // Check settings

                // Update the condition check
                // const parsedSettings = JSON.parse(formDataResponse.settings);
                const settings = typeof formDataResponse.settings === 'string' 
                ? JSON.parse(formDataResponse.settings) 
                : formDataResponse.settings;
                setParsedSettings(settings);

                 // Initialize signature pad with the dimensions from settings
      if (settings.signatureEnabled === 1) {
        const signatureWidth = settings.signatureWidth || 400;
        const signatureHeight = settings.signatureHeight || 200;
        
        console.log(`Signature dimensions: ${signatureWidth}x${signatureHeight}`);
        
        // We'll use these dimensions later when rendering the SignaturePad component
      }

                console.log("Parsed Settings:", settings); // Debug log
              // Check if GDPR is enabled in form settings
              const gdprEnabled = settings?.gdprEnabled === 1;
              
              // Only show GDPR banner if enabled in form settings
              if (gdprEnabled) {
                const consent = localStorage.getItem("gdpr_consent");
                if (!consent) {
                  setShowGDPR(true);
                }
              } else {
                setShowGDPR(false);
              }
                // Access loginRequired
                const loginRequired = settings.loginRequired;
                console.log("loginRequired:", loginRequired); // Debug log
                if (Number(loginRequired) === 1) {
                    const formUserToken = localStorage.getItem("formUserToken");

                    if (!formUserToken) {
                        const currentUrl = encodeURIComponent(
                            window.location.href
                        );
                        window.location.href = `/form-login?redirect=${currentUrl}`;

                        return;
                    }
                }

                // Continue with form data processing...
                const parsedJdata =
                    typeof formDataResponse.jdata === "string"
                        ? JSON.parse(formDataResponse.jdata)
                        : formDataResponse.jdata;

                          // Check if GDPR is enabled in form data
   
                setFormData({
                    ...formDataResponse,
                    jdata: parsedJdata.filter((field) => !field.isRemoved),
                });

                setFormFields(parsedJdata.filter((field) => !field.isRemoved));
            } catch (error) {
                console.error("Error:", error);
                toast.error("Failed to load form");

                if (error.response?.status === 401) {
                    const currentUrl = encodeURIComponent(window.location.href);
                    window.location.href = `/form-login?redirect=${currentUrl}`;
                }
            } finally {
                setLoading(false);
            }
        };

        checkLoginAndFetchForm();
    }, []);

   
// console.log(field.label); // Debug log
        // Add signature validation
        // Updated validate function for form submission
const validateForm = () => {
  const newErrors = {};
  let isValid = true;

  formFields.forEach((field) => {
    if (field.required && !responses[field.label]) {
      newErrors[field.label] = `${field.label} is required`;
      isValid = false;
    }
  });

  // Parse settings properly first
  const parsedSettings = typeof formData.settings === 'string' 
    ? JSON.parse(formData.settings) 
    : formData.settings;
  
  // Add signature validation
  if (parsedSettings.signatureEnabled === 1) {
    if (!signaturePad || signaturePad.isEmpty()) {
      setSignatureError('Signature is required');
      isValid = false;
    } else {
      setSignatureError('');
    }
  }

  setErrors(newErrors);
  return isValid;
};

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         if (!validateForm()) return;

//         setSubmitting(true);
//         try {
//             const pathSegments = window.location.pathname.split("/");
//             const formId = pathSegments[pathSegments.length - 1];
//             const formUserToken = localStorage.getItem("formUserToken");

//             // If login is required but no token, redirect to login
//             console.log("Form data:", formData);
//             const parsedSettings = JSON.parse(formData.settings);
//             // Access loginRequired
//             const loginRequired = parsedSettings.loginRequired; // Debug log
//             console.log("loginRequired:", loginRequired); // Debug log
//             if (Number(loginRequired) ===1  && !formUserToken) {
//                 console.log("Login required for submission, redirecting..."); // Debug log
//                 // const currentUrl = encodeURIComponent(window.location.href);
//                 // window.location.href = `/form-login?redirect=${currentUrl}`;
//                 return;
//             }

//             const payload = {
//                 formId,
//                 response: Object.keys(responses).reduce((acc, key) => {
//                     if (responses[key]) {
//                         acc[key] = responses[key];
//                     }
//                     return acc;
//                 }, {}), 
//             };

//     // Add signature data if enabled
  

// if (parsedSettings.signatureEnabled === 1 && signaturePad) {
//   payload.signature = signaturePad.toDataURL();
// }
//             // Add userToken if login was required
//             if (Number(loginRequired) === 1) {
//                 console.log("Adding userToken to payload...", formUserToken); // Debug log
//                 payload.userToken = formUserToken;
//             }
//             console.log("payload:", payload);
//             const response = await axios.post(
//                 `${apiEndpoints.form}/submit-form`,
//                 payload
//             );
//      console.log("payload:", payload); // Check full response
//             if (response.status === 200 || response.status === 201) {
//                 setSubmitted(true);
//                 toast.success("Form submitted successfully!");
//                 localStorage.removeItem('formUserToken');
//             }
//         } catch (error) {
//             console.error("Error submitting form:", error);
//             if (error.response?.status === 401) {
//                 toast.error("Please log in to submit the form");
//                 // const currentUrl = encodeURIComponent(window.location.href);
//                 // window.location.href = `/form-login?redirect=${currentUrl}`;
//             } else {
//                 toast.error(
//                     error.response?.data?.message || "Failed to submit form"
//                 );
//             }
//         } finally {
//             setSubmitting(false);
//         }
//     };

// Create a function to convert signature to a file and upload it
const uploadSignature = async (signaturePad, formId, userToken) => {
  if (!signaturePad || signaturePad.isEmpty()) {
    return null;
  }

  try {
    // Get the signature as data URL
    const dataURL = signaturePad.toDataURL('image/png');
    
    // Convert the data URL to a Blob
    const res = await fetch(dataURL);
    const blob = await res.blob();
    
    // Create a File object from the Blob
    const signatureFile = new File([blob], 'signature.png', { type: 'image/png' });
    
    // Create FormData for the API request
    const formData = new FormData();
    formData.append('formId', formId);
    if (userToken) {
      formData.append('userToken', userToken);
    }
    formData.append('signature', signatureFile);
    
    // Make the API request to upload the signature
    const response = await axios.post(`${apiEndpoints.form}/submit-form-signature`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    
    // Return the URL of the uploaded signature
    if (response.data && response.data.status && response.data.url) {
      return response.data.url;
    }
    
    throw new Error('Failed to get signature URL from API response');
  } catch (error) {
    console.error('Error uploading signature:', error);
    toast.error('Failed to upload signature');
    return null;
  }
};

// Update the handleSubmit function to include signature upload
const handleSubmit = async (e) => {
  e.preventDefault();
  if (!validateForm()) return;

  setSubmitting(true);
  try {
    const pathSegments = window.location.pathname.split("/");
    const formId = pathSegments[pathSegments.length - 1];
    const formUserToken = localStorage.getItem("formUserToken");

    // Parse settings properly
    const parsedSettings = typeof formData.settings === 'string' 
      ? JSON.parse(formData.settings) 
      : formData.settings;

    // Check if login is required
    const loginRequired = parsedSettings.loginRequired;
    if (Number(loginRequired) === 1 && !formUserToken) {
      toast.error("Please log in to submit the form");
      return;
    }

    // Prepare form submission payload
    const payload = {
      formId,
      response: Object.keys(responses).reduce((acc, key) => {
        if (responses[key]) {
          acc[key] = responses[key];
        }
        return acc;
      }, {}),
    };

    // Add userToken if login was required
    if (Number(loginRequired) === 1) {
      payload.userToken = formUserToken;
    }

    // If signature is enabled, upload it first
    if (parsedSettings.signatureEnabled === 1 && signaturePad) {
      // Show uploading toast
      toast.info("Uploading signature...");
      
      // Upload signature and get the URL
      const signatureUrl = await uploadSignature(signaturePad, formId, formUserToken);
      
      if (signatureUrl) {
        // Add signature URL to the response payload
        payload.response.signature = signatureUrl;
      } else {
        toast.error("Failed to upload signature");
        setSubmitting(false);
        return;
      }
    }

    // Submit the form with all data including the signature URL
    const response = await axios.post(
      `${apiEndpoints.form}/submit-form`,
      payload
    );

    if (response.status === 200 || response.status === 201) {
      setSubmitted(true);
      toast.success("Form submitted successfully!");
      localStorage.removeItem('formUserToken');
    }
  } catch (error) {
    console.error("Error submitting form:", error);
    if (error.response?.status === 401) {
      toast.error("Please log in to submit the form");
    } else {
      toast.error(
        error.response?.data?.message || "Failed to submit form"
      );
    }
  } finally {
    setSubmitting(false);
  }
};

    const handleInputChange = (name, value) => {
        setResponses((prev) => ({
            ...prev,
            [name]: value,
        }));
        // Clear error when user starts typing
        if (errors[name]) {
            setErrors((prev) => ({
                ...prev,
                [name]: undefined,
            }));
        }
    };

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <motion.div
                    animate={{ rotate: 360 }}
                    transition={{ duration: 1, repeat: Infinity }}>
                    <Loader2 className="w-12 h-12 text-cyan-500" />
                </motion.div>
            </div>
        );
    }

    if (submitted) {
        return (
            <motion.div className="min-h-screen flex items-center justify-center p-4">
                <div className="w-full max-w-2xl bg-gradient-to-br from-cyan-50 via-white to-cyan-50 p-8 rounded-2xl shadow-xl">
                    <div className="text-center">
                        <CheckCircle className="w-20 h-20 mx-auto text-cyan-500 mb-6" />
                        <h2 className="text-3xl font-bold text-gray-800 mb-4">
                            Thank You!
                        </h2>
                        <p className="text-gray-600">
                            Your form has been submitted successfully.
                        </p>
                    </div>
                </div>
            </motion.div>
        );
    }

    return (
        <motion.div className="min-h-screen flex items-center justify-center p-4">
            <div className="w-full max-w-xl bg-white rounded-2xl shadow-xl overflow-hidden">
                <div className="bg-gradient-to-r from-cyan-600 to-slate-600 p-6">
                    <div className="flex items-center gap-3">
                        <FileText className="w-8 h-8 text-white" />
                        <h1 className="text-3xl font-bold text-white">
                            {formData?.name}
                        </h1>
                    </div>
                    <p className="mt-3 text-cyan-100 text-lg">
                        {formData?.description}
                    </p>
                </div>

                <form onSubmit={handleSubmit} className="p-6 space-y-4">
                    <AnimatePresence>
                        {formFields.map((field, index) => (
                            <motion.div
                                key={index}
                                className="bg-white p-4 rounded-lg border hover:border-cyan-500 transition-all duration-200"
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}>
                                <label className="block">
                                    <span className="text-base font-semibold text-gray-700">
                                        {field.label}
                                        {field.required && (
                                            <span className="text-red-500 ml-1">
                                                *
                                            </span>
                                        )}
                                    </span>
                                    {field.type === "textarea" ? (
                                        <textarea
                                            value={responses[field.label] || ""}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    field.label,
                                                    e.target.value
                                                )
                                            }
                                            placeholder={field.placeHolder}
                                            className={`mt-1  outline-none  block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 ${
                                                errors[field.label]
                                                    ? "border-red-500"
                                                    : ""
                                            }`}
                                            rows="4"
                                        />
                                    ) : (
                                        <input
                                            type={field.type}
                                            value={responses[field.label] || ""}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    field.label,
                                                    e.target.value
                                                )
                                            }
                                            placeholder={field.placeHolder}
                                            className={`mt-1 outline-none block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 ${
                                                errors[field.label]
                                                    ? "border-red-500"
                                                    : ""
                                            }`}
                                        />
                                    )}
                                    {errors[field.label] && (
                                        <p className="mt-1 text-sm text-red-500">
                                            {errors[field.label]}
                                        </p>
                                    )}
                                </label>
                            </motion.div>
                        ))}
                    </AnimatePresence>

{/* Signature Pad */}
{/* Signature Pad with dynamic dimensions */}
{parsedSettings.signatureEnabled === 1 && (
  <motion.div
    className="bg-white p-4 rounded-lg border hover:border-cyan-500 transition-all duration-200"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
  >
    <label className="block">
      <span className="text-base font-semibold text-gray-700">
        Digital Signature
        <span className="text-red-500 ml-1">*</span>
      </span>
    </label>
    
    <div className="mt-2 border rounded-lg overflow-hidden bg-white">
      <div style={{ 
        height: `${parsedSettings.signatureHeight || 200}px`, 
        width: `${parsedSettings.signatureWidth || 400}px`,
        maxWidth: '100%',
        margin: '0 auto'
      }}>
        <SignaturePad
          ref={(ref) => setSignaturePad(ref)}
          options={{
            minWidth: 1,
            maxWidth: 2,
            penColor: 'black',
            backgroundColor: 'rgb(255, 255, 255)',
            canvasWidth: parsedSettings.signatureWidth || 400,
            canvasHeight: parsedSettings.signatureHeight || 200
          }}
        />
      </div>
    </div>

    {signatureError && (
      <p className="mt-1 text-sm text-red-500">{signatureError}</p>
    )}

    <div className="mt-2 flex justify-end space-x-2">
      <motion.button
        type="button"
        onClick={downloadSignatureAsPNG}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        className="px-3 py-1.5 text-sm bg-cyan-600 text-white hover:bg-cyan-700 rounded-md transition-colors"
      >
        Download PNG
      </motion.button>
      <motion.button
        type="button"
        onClick={handleClearSignature}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        className="px-3 py-1.5 text-sm bg-gray-100 text-gray-600 hover:bg-gray-200 rounded-md transition-colors"
      >
        Clear Signature
      </motion.button>
    </div>
  </motion.div>
)}
                    <div className="flex justify-between items-center pt-4">
                        <p className="text-rose-500 text-xs">
                            * Required fields
                        </p>
                        <motion.button
                            type="submit"
                            disabled={submitting}
                            className="px-6 py-2 bg-gradient-to-r from-cyan-600 to-slate-600 text-white rounded-lg 
                        disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
                            whileHover={{ scale: 1.02 }}>
                            {submitting ? (
                                <>
                                    <Loader2 className="w-4 h-4 animate-spin" />
                                    Submitting...
                                </>
                            ) : (
                                "Submit Form"
                            )}
                        </motion.button>
                    </div>
                </form>
                
            </div>

            <AnimatePresence>
        {showGDPR && (
          <GDPRBanner
            onAccept={handleAcceptGDPR}
            onDeny={handleDenyGDPR}
            onManagePreferences={() => setShowPreferences(true)}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showPreferences && (
          <PreferencesModal
            isOpen={showPreferences}
            onClose={() => setShowPreferences(false)}
            onSave={handleSavePreferences}
          />
        )}
      </AnimatePresence>
    </motion.div>

       
    );
};

export default UserForm;