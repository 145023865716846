import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBH0TpRXbEKDSVdsDOHTHjlnA5q3o-y2Mg",
  authDomain: "ecommercenext-97f67.firebaseapp.com",
  projectId: "ecommercenext-97f67",
  storageBucket: "ecommercenext-97f67.firebasestorage.app",
  messagingSenderId: "595087205883",
  appId: "1:595087205883:web:682150d7f99b6df614175e",
  measurementId: "G-T2N35B2BRJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging
const messaging = getMessaging(app);

// Function to request permission and get the FCM token
export const requestFCMToken = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: "BKzckBNZxaGPLcByOCAlstrE_9qPGM-l5eK9NEhF1wJtVjuF1XBGmSpmNpbFEiBbIx3Cd5cFHEJGPDyzpPi8JWg",
      });
      console.log("FCM Token:", token);
      return token;
    } else {
      console.error("Notification permission denied");
      return null;
    }
  } catch (error) {
    console.error("Error getting FCM token:", error);
  }
};

// Listen for incoming messages
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("New foreground message:", payload);
      resolve(payload);
    });
  });

export { messaging };