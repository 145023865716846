// import React, { useState, useRef, useEffect } from 'react';
// import { motion, AnimatePresence } from 'framer-motion';
// import { ArrowUpDown, Calendar, Mail, Phone, Download, FileText, ChevronDown, FileDown } from 'lucide-react';
// import * as XLSX from 'xlsx';
// import jsPDF from 'jspdf';
// import 'jspdf-autotable';

// const FormResponsesTable = ({ responses, formFields }) => {
//     const [activeDropdown, setActiveDropdown] = useState(null);
//     const dropdownRef = useRef(null);
//     const [selectedResponse, setSelectedResponse] = useState(null);

//     // Close dropdown when clicking outside
//     useEffect(() => {
//         const handleClickOutside = (event) => {
//             if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//                 setActiveDropdown(null);
//             }
//         };
//         document.addEventListener('mousedown', handleClickOutside);
//         return () => {
//             document.removeEventListener('mousedown', handleClickOutside);
//         };
//     }, []);

//     const formatValue = (value, type) => {
//       if (!value) return '-';
//       if (type === 'date') {
//         return new Date(value).toLocaleDateString();
//       }
//       return value;
//     };

//     const getFieldsFromResponses = () => {
//       if (!responses.length) return [];
//       const firstResponse = JSON.parse(responses[0].response);
//       return Object.keys(firstResponse).map(key => {
//         const fieldConfig = formFields.filter(f => !f.isRemoved).find(f => f.name === key);
//         return {
//           key,
//           label: fieldConfig?.label || key,
//           type: fieldConfig?.type || 'text'
//         };
//       });
//     };

//     // const exportToExcel = () => {
//     //     const headers = ['No.', 'Timestamp', ...fields.map(field => field.label)];
//     //     const data = responses.map((response, index) => {
//     //         const parsedResponse = JSON.parse(response.response);
//     //         return [
//     //             index + 1,
//     //             new Date(response.created_at).toLocaleString(),
//     //             ...fields.map(field => formatValue(parsedResponse[field.key], field.type))
//     //         ];
//     //     });

//     //     const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
//     //     const workbook = XLSX.utils.book_new();
//     //     XLSX.utils.book_append_sheet(workbook, worksheet, 'Responses');
//     //     XLSX.writeFile(workbook, 'form_responses.xlsx');
//     // };

//     // const exportToPDF = () => {
//     //     const doc = new jsPDF();
//     //     const headers = ['No.', 'Timestamp', ...fields.map(field => field.label)];
//     //     const data = responses.map((response, index) => {
//     //         const parsedResponse = JSON.parse(response.response);
//     //         return [
//     //             index + 1,
//     //             new Date(response.created_at).toLocaleString(),
//     //             ...fields.map(field => formatValue(parsedResponse[field.key], field.type))
//     //         ];
//     //     });

//     //     doc.text('Form Responses', 14, 10);
//     //     doc.autoTable({
//     //         head: [headers],
//     //         body: data
//     //     });
//     //     doc.save('form_responses.pdf');
//     // };

//     // Function to export a single response's template content as PDF
//     const exportResponseTemplate = (templateContent, templateName) => {
//         const doc = new jsPDF();
        
//         // Set page margins (in mm)
//         const margin = 20;
//         const pageWidth = doc.internal.pageSize.getWidth();
//         const textWidth = pageWidth - (margin * 2);
        
//         // Split content by newlines
//         const lines = templateContent.split('\n');
        
//         // Set initial position
//         let yPos = margin;
        
//         // Add title
//         doc.setFontSize(16);
//         doc.setFont('helvetica', 'bold');
//         // Center the title
//         const titleWidth = doc.getStringUnitWidth(templateName) * 16 / doc.internal.scaleFactor;
//         const titleX = (pageWidth - titleWidth) / 2;
//         doc.text(templateName, titleX, yPos);
//         yPos += 10;
        
//         // Set font for body text
//         doc.setFontSize(12);
//         doc.setFont('helvetica', 'normal');
        
//         // Process each line of content
//         lines.forEach(line => {
//             // Skip empty lines but still add some space
//             if (line.trim() === '') {
//                 yPos += 5;
//                 return;
//             }
            
//             // Check if we need a new page
//             if (yPos > 270) {
//                 doc.addPage();
//                 yPos = margin;
//             }
            
//             // Check if line is too long and needs to be split
//             const textLines = doc.splitTextToSize(line, textWidth);
            
//             // Add each split line
//             textLines.forEach(splitLine => {
//                 doc.text(splitLine, margin, yPos);
//                 yPos += 7; // Line height
                
//                 // Check if we need a new page after adding a line
//                 if (yPos > 270) {
//                     doc.addPage();
//                     yPos = margin;
//                 }
//             });
//         });
        
//         // Save the PDF with the template name
//         doc.save(`${templateName || 'template'}.pdf`);
//     };
    

//     const fields = getFieldsFromResponses();

//     // Show template dropdown for a specific response
//     const openTemplateOptions = (response) => {
//         setSelectedResponse(response);
//     };

//     return (
//       <div className="bg-white rounded-lg shadow-lg overflow-hidden mt-8">
//         {/* Template Selection Dropdown - Now at top level */}
//         <AnimatePresence>
//           {selectedResponse && (
//             <motion.div
//               initial={{ opacity: 0, y: -20 }}
//               animate={{ opacity: 1, y: 0 }}
//               exit={{ opacity: 0, y: -20 }}
//               className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50"
//               onClick={() => setSelectedResponse(null)}
//             >
//               <motion.div
//                 initial={{ scale: 0.9 }}
//                 animate={{ scale: 1 }}
//                 exit={{ scale: 0.9 }}
//                 className="bg-white rounded-xl shadow-2xl p-5 m-4 max-w-lg w-full"
//                 onClick={(e) => e.stopPropagation()}
//               >
//                 <div className="flex justify-between items-center mb-4 pb-3 border-b">
//                   <h3 className="text-lg font-bold text-gray-800">Export Template</h3>
//                   <button 
//                     onClick={() => setSelectedResponse(null)}
//                     className="p-1 rounded-full hover:bg-gray-100"
//                   >
//                     <svg className="w-5 h-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
//                       <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
//                     </svg>
//                   </button>
//                 </div>
                
//                 <p className="text-sm text-gray-500 mb-4">
//                   Select a template to export as PDF. The content will be populated with the response data.
//                 </p>
                
//                 <div className="space-y-2 max-h-80 overflow-y-auto">
//                   {selectedResponse.formResponseWithTemplate.map((template, idx) => (
//                     <motion.button
//                       key={template.templateId}
//                       // whileHover={{ scale: 1.02 }}
//                       whileTap={{ scale: 0.98 }}
//                       onClick={() => {
//                         exportResponseTemplate(template.contentWithUserResponse, template.name);
//                         setSelectedResponse(null);
//                       }}
//                       className="w-full p-3 bg-gradient-to-r from-indigo-50 to-blue-50 hover:from-indigo-100 hover:to-blue-100 rounded-lg flex items-center gap-4 transition-colors text-left border border-gray-100"
//                     >
//                       <div className="bg-gradient-to-r from-cyan-600 to-slate-600 p-3 rounded-full text-white">
//                         <FileText size={18} />
//                       </div>
//                       <div className="flex-1">
//                         <div className="font-medium text-gray-800">
//                           {template.name || `Template ${idx + 1}`}
//                         </div>
//                         <div className="text-xs text-gray-500 mt-1">
//                           Click to download as PDF document
//                         </div>
//                       </div>
//                     </motion.button>
//                   ))}
//                 </div>
//               </motion.div>
//             </motion.div>
//           )}
//         </AnimatePresence>

//         {/* <div className="p-6 border-b">
//           <div className="flex justify-between items-center">
//             <h2 className="text-xl font-semibold text-gray-800">Form Responses ({responses.length})</h2>
//             <div className="flex gap-4">
//               <motion.button
//                 onClick={exportToExcel}
//                 whileHover={{ scale: 1.02 }}
//                 className="px-4 py-2 flex items-center gap-2 bg-gradient-to-r from-cyan-600 to-slate-600 text-white rounded-lg text-base shadow-sm hover:shadow"
//               >
//                 <Download size={18} />
//                 Excel
//               </motion.button>
//               <motion.button
//                 onClick={exportToPDF}
//                 whileHover={{ scale: 1.02 }}
//                 className="px-4 py-2 flex items-center gap-2 bg-gradient-to-r from-cyan-600 to-slate-600 text-white rounded-lg text-base shadow-sm hover:shadow"
//               >
//                 <FileText size={18} />
//                 PDF
//               </motion.button>
//             </div>
//           </div>
//         </div> */}

//         <div className="overflow-x-auto">
//           <table className="w-full min-w-[800px]">
//             <thead>
//               <tr className="bg-gray-50">
//                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                   No.
//                 </th>
//                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                   Timestamp
//                 </th>
//                 {fields.map(field => (
//                   <th key={field.key} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                     {field.label}
//                   </th>
//                 ))}
//                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                   Actions
//                 </th>
//               </tr>
//             </thead>
//             <tbody className="bg-white divide-y divide-gray-200">
//               {responses.map((response, index) => {
//                 const parsedResponse = JSON.parse(response.response);
//                 const hasTemplates = response.formResponseWithTemplate && 
//                                      response.formResponseWithTemplate.length > 0;
                
//                 return (
//                   <tr 
//                     key={response.id}
//                     className="hover:bg-gray-50 transition-colors"
//                   >
//                     <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                       {index + 1}
//                     </td>
//                     <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
//                       {new Date(response.created_at).toLocaleString()}
//                     </td>
//                     {fields.map(field => (
//                       <td key={field.key} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                         {formatValue(parsedResponse[field.key], field.type)}
//                       </td>
//                     ))}
//                     <td className="px-6 py-4 whitespace-nowrap">
//                       <div className="flex items-center gap-3">
//                         {hasTemplates && (
//                           <motion.button
//                             onClick={() => openTemplateOptions(response)}
//                             whileHover={{ scale: 1.05 }}
//                             whileTap={{ scale: 0.95 }}
//                             className="px-3 py-2 flex items-center gap-2 bg-gradient-to-r from-cyan-600 to-slate-600 text-white rounded-lg shadow-sm hover:shadow transition-all duration-200 text-sm font-medium"
//                           >
//                             <FileDown size={16} />
//                             Export
//                           </motion.button>
//                         )}
                        
//                         <div className="flex gap-2">
//                           {response.email && <Mail className="w-4 h-4 text-gray-400" />}
//                           {response.phone && <Phone className="w-4 h-4 text-gray-400" />}
//                         </div>
//                       </div>
//                     </td>
//                   </tr>
//                 );
//               })}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     );
// };

// export default FormResponsesTable;  

import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowUpDown, Calendar, Mail, Phone, Download, FileText, ChevronDown, FileDown } from 'lucide-react';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';
import { toast } from 'react-toastify';
import { apiEndpoints } from '../config/config';

const FormResponsesTable = ({ responses, formFields, formData }) => {
    const [activeDropdown, setActiveDropdown] = useState(null);
    const dropdownRef = useRef(null);
    const [selectedResponse, setSelectedResponse] = useState(null);
    const [exportLoading, setExportLoading] = useState(false);
    const [parsedFormData, setParsedFormData] = useState(null);

    // Parse formData when component mounts or formData changes
    useEffect(() => {
        if (formData) {
           
                // If formData is already an object, use it directly
                if (typeof formData === 'object') {
                    setParsedFormData(formData);
                    console.log("FormData is already an object:", formData);
                } 
                // If formData is a string, try to parse it
              
        }
    }, [formData]);
    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setActiveDropdown(null);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const formatValue = (value, type) => {
      if (!value) return '-';
      if (type === 'date') {
        return new Date(value).toLocaleDateString();
      }
      return value;
    };

    const getFieldsFromResponses = () => {
      if (!responses.length) return [];
      const firstResponse = JSON.parse(responses[0].response);
      return Object.keys(firstResponse).map(key => {
        const fieldConfig = formFields.filter(f => !f.isRemoved).find(f => f.name === key);
        return {
          key,
          label: fieldConfig?.label || key,
          type: fieldConfig?.type || 'text'
        };
      });
    };

    // Export the HTML content to PDF using the HTML-to-PDF API
    // Export the HTML content to PDF using the HTML-to-PDF API
const exportHtmlToPdf = async (template, response, formData) => {
  try {
      setExportLoading(true);
      
      // Show loading toast
      const toastId = toast.loading("Generating PDF...");
      
      // Get the HTML content from the template
      // Check if the HTML content is in contentWithUserResponse or content
      let htmlContent = template.contentWithUserResponse || template.content;
      
      // If we still don't have html content, log error and return
      if (!htmlContent) {
          console.error("No HTML content found in template:", template);
          toast.error("Template has no HTML content to export");
          return;
      }
      
      
      console.log("HTML content after replacing form fields:", response);
    
      
     // Important: Use responseUrl from parsedFormData
     let formId;
          
     if (parsedFormData?.responseUrl) {
         formId = parsedFormData.responseUrl;
         console.log("Using formId from parsedFormData.responseUrl:", formId);
     
     }

      console.log("Form ID:", formId);
      const payload = new URLSearchParams();
      payload.append('html', htmlContent);
      payload.append('fileName', `${template.name || 'Export'}`);
      payload.append('formId', formId);
      
      console.log("Sending HTML content to API:", htmlContent.substring(0, 100) + "..."); // Log the first 100 chars
      
      const apiResponse = await axios.post(`${apiEndpoints.form}/html-to-pdf`, payload, {
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
          },
          responseType: 'blob'  // Important: To handle binary data
      });
      
      // Create a download link for the PDF
      const url = window.URL.createObjectURL(new Blob([apiResponse.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${template.name || 'Export'}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      // Update toast to success
      toast.update(toastId, { 
          render: "PDF generated and downloaded successfully", 
          type: "success", 
          isLoading: false,
          autoClose: 3000
      });
  } catch (error) {
      console.error("Error exporting to PDF:", error);
      toast.error(`Failed to generate PDF: ${error.message}`);
  } finally {
      setExportLoading(false);
      setSelectedResponse(null);
  }
};

    const fields = getFieldsFromResponses();

    // Show template dropdown for a specific response
    const openTemplateOptions = (response) => {
        setSelectedResponse(response);
    };

    return (
      <div className="bg-white rounded-lg shadow-lg overflow-hidden mt-8">
        {/* Template Selection Dropdown - Now at top level */}
        <AnimatePresence>
          {selectedResponse && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50"
              onClick={() => setSelectedResponse(null)}
            >
              <motion.div
                initial={{ scale: 0.9 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.9 }}
                className="bg-white rounded-xl shadow-2xl p-5 m-4 max-w-lg w-full"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="flex justify-between items-center mb-4 pb-3 border-b">
                  <h3 className="text-lg font-bold text-gray-800">Export Template</h3>
                  <button 
                    onClick={() => setSelectedResponse(null)}
                    className="p-1 rounded-full hover:bg-gray-100"
                  >
                    <svg className="w-5 h-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                  </button>
                </div>
                
                <p className="text-sm text-gray-500 mb-4">
                  Select a template to export as PDF. The content will be populated with the response data.
                </p>
                
                <div className="space-y-2 max-h-80 overflow-y-auto">
                  {selectedResponse.formResponseWithTemplate.map((template, idx) => (
                    <motion.button
                      key={template.templateId}
                      whileTap={{ scale: 0.98 }}
                      onClick={() => {
                        exportHtmlToPdf(template, selectedResponse);
                        setSelectedResponse(null);
                      }}
                      disabled={exportLoading}
                      className="w-full p-3 bg-gradient-to-r from-indigo-50 to-blue-50 hover:from-indigo-100 hover:to-blue-100 rounded-lg flex items-center gap-4 transition-colors text-left border border-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      <div className="bg-gradient-to-r from-cyan-600 to-slate-600 p-3 rounded-full text-white">
                        <FileText size={18} />
                      </div>
                      <div className="flex-1">
                        <div className="font-medium text-gray-800">
                          {template.name || `Template ${idx + 1}`}
                        </div>
                        <div className="text-xs text-gray-500 mt-1">
                          Click to download as PDF document
                        </div>
                      </div>
                      {exportLoading && (
                        <div className="w-5 h-5 border-2 border-gray-300 border-t-cyan-600 rounded-full animate-spin"></div>
                      )}
                    </motion.button>
                  ))}
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        <div className="overflow-x-auto">
          <table className="w-full min-w-[800px]">
            <thead>
              <tr className="bg-gray-50">
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  No.
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Timestamp
                </th>
                {fields.map(field => (
                  <th key={field.key} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {field.label}
                  </th>
                ))}
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {responses.map((response, index) => {
                const parsedResponse = JSON.parse(response.response);
                const hasTemplates = response.formResponseWithTemplate && 
                                     response.formResponseWithTemplate.length > 0;
                
                return (
                  <tr 
                    key={response.id}
                    className="hover:bg-gray-50 transition-colors"
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {index + 1}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {new Date(response.created_at).toLocaleString()}
                    </td>
                    {fields.map(field => (
                      <td key={field.key} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {formatValue(parsedResponse[field.key], field.type)}
                      </td>
                    ))}
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center gap-3">
                        {hasTemplates && (
                          <motion.button
                            onClick={() => openTemplateOptions(response)}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            className="px-3 py-2 flex items-center gap-2 bg-gradient-to-r from-cyan-600 to-slate-600 text-white rounded-lg shadow-sm hover:shadow transition-all duration-200 text-sm font-medium"
                            disabled={exportLoading}
                          >
                            {exportLoading ? (
                              <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                            ) : (
                              <FileDown size={16} />
                            )}
                            Export
                          </motion.button>
                        )}
                        
                        <div className="flex gap-2">
                          {response.email && <Mail className="w-4 h-4 text-gray-400" />}
                          {response.phone && <Phone className="w-4 h-4 text-gray-400" />}
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
};

export default FormResponsesTable;