import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { UserPlus, Loader2, X, Users, Trash2, RefreshCw, Shield, Mail, Lock } from 'lucide-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { apiEndpoints } from '../config/config';

const AddUser = ({ formId, userId, formUsers: initialFormUsers }) => {
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    password: ''
  });
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [users, setUsers] = useState(initialFormUsers || []);

  const generatePassword = () => {
    const length = 10;
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*";
    let password = "";
    for (let i = 0; i < length; i++) {
      password += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    return password;
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.email) newErrors.email = 'Email is required';
    else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) newErrors.email = 'Valid email is required';
    
    if (!formData.name) newErrors.name = 'Name is required';
    else if (formData.name.length < 2) newErrors.name = 'Name must be at least 2 characters';
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddClick = () => {
    setFormData({
      email: '',
      name: '',
      password: generatePassword()
    });
    setShowForm(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setSubmitting(true);
    try {
      const response = await axios.post(
        `${apiEndpoints.form}/add-user`,
        {
          userId,
          formId,
          email: formData.email,
          name: formData.name,
          password: formData.password
        }
      );

      if (response.status === 201 || response.status === 200) {
        toast.success('User added successfully!');
        setUsers(prevUsers => [...prevUsers, response.data]);
        setShowForm(false);
        setFormData({ email: '', name: '', password: '' });
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to add user');
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      await axios.delete(`${apiEndpoints.form}/delete-user`, {
        data: {
          userId: userId,
          formId: formId,
          id: id
        }
      });
      setUsers(prevUsers => prevUsers.filter(user => user.id !== id));
      toast.success('User removed successfully');
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to remove user');
    }
  };

  return (
    <div className="mt-0">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-3">
          <div className="bg-cyan-100 p-2.5 rounded-full">
            <Shield className="w-5 h-5 text-cyan-600" />
          </div>
          <div>
            <h2 className="text-xl font-semibold text-gray-800">Authorized Users</h2>
            <p className="text-sm text-gray-500">Manage users who can access this form</p>
          </div>
        </div>
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={handleAddClick}
          className="px-4 py-2.5 bg-gradient-to-r from-cyan-600 to-slate-600 hover:from-cyan-700 hover:to-cyan-700 text-white rounded-lg shadow-sm flex items-center gap-2 font-medium transition-all duration-200"
        >
          <UserPlus className="w-4 h-4" />
          Add User
        </motion.button>
      </div>

      <AnimatePresence>
        {showForm && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
            className="bg-white p-6 rounded-xl shadow-md border border-cyan-100 mb-6"
          >
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium text-gray-800 flex items-center gap-2">
                <UserPlus className="w-5 h-5 text-cyan-500" />
                Add New User
              </h3>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setShowForm(false)}
                className="text-gray-400 hover:text-gray-600 bg-gray-100 hover:bg-gray-200 rounded-full p-1.5 transition-colors"
              >
                <X className="w-5 h-5" />
              </motion.button>
            </div>

            <form onSubmit={handleSubmit} className="space-y-5">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Name</label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Users className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="text"
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    className={`pl-10 pr-3 py-2.5 block w-full rounded-lg shadow-sm outline-none focus:ring-2 focus:ring-cyan-500 ${
                      errors.name ? 'border-red-300 bg-red-50' : 'border-gray-300'
                    }`}
                    placeholder="Enter user name"
                  />
                </div>
                {errors.name && (
                  <p className="mt-1.5 text-sm text-red-500 flex items-center gap-1.5">
                    <span className="w-1 h-1 bg-red-500 rounded-full inline-block"></span>
                    {errors.name}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Email ID</label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Mail className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="email"
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    className={`pl-10 pr-3 py-2.5 block w-full rounded-lg shadow-sm outline-none focus:ring-2 focus:ring-cyan-500 ${
                      errors.email ? 'border-red-300 bg-red-50' : 'border-gray-300'
                    }`}
                    placeholder="Enter user email id"
                  />
                </div>
                {errors.email && (
                  <p className="mt-1.5 text-sm text-red-500 flex items-center gap-1.5">
                    <span className="w-1 h-1 bg-red-500 rounded-full inline-block"></span>
                    {errors.email}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Generated Password</label>
                <div className="flex gap-2">
                  {/* <div className="relative flex-grow"> */}
                    <div className=" inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Lock className="h-5 w-5 text-red-400" />
                    </div>
                    <input
                      type="text"
                      value={formData.password}
                      readOnly
                      className="pl-10 pr-3 py-2.5 inline-block w-fit rounded-lg border-gray-300 bg-gray-50 shadow-sm"
                    />
                  {/* </div> */}
                  <motion.button
                    type="button"
                    onClick={() => setFormData({ ...formData, password: generatePassword() })}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className="px-4 py-2.5 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-lg border border-gray-300 flex items-center gap-2 transition-colors"
                  >
                    <RefreshCw className="w-4 h-4" />
                    Generate
                  </motion.button>
                </div>
                <p className="mt-1.5 text-xs text-gray-500">
                  This password will be shared with the user to access the form
                </p>
              </div>

              <div className="flex justify-end pt-2">
                <motion.button
                  type="button"
                  onClick={() => setShowForm(false)}
                  className="mr-3 px-4 py-2.5 bg-white border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  Cancel
                </motion.button>
                <motion.button
                  type="submit"
                  disabled={submitting}
                  className="px-4 py-2.5 bg-gradient-to-r from-cyan-600 to-slate-600 text-white rounded-lg shadow-sm flex items-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  {submitting ? (
                    <>
                      <Loader2 className="w-4 h-4 animate-spin" />
                      Adding User...
                    </>
                  ) : (
                    <>Add User</>
                  )}
                </motion.button>
              </div>
            </form>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="bg-white rounded-xl shadow-sm overflow-hidden border border-gray-200">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Email
              </th>
              <th className="px-6 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Password
              </th>
              <th className="px-6 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {users?.length === 0 ? (
              <tr>
                <td colSpan="4" className="px-6 py-8 text-center text-gray-500">
                  <div className="flex flex-col items-center">
                    <Shield className="w-10 h-10 text-gray-300 mb-2" />
                    <p className="text-gray-500">No authorized users yet</p>
                    <p className="text-sm text-gray-400">Add users to allow form access</p>
                  </div>
                </td>
              </tr>
            ) : (
              users.map((user) => (
                <tr key={user?.id} className="hover:bg-gray-50 transition-colors">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 h-8 w-8 bg-cyan-100 rounded-full flex items-center justify-center">
                        <span className="text-sm font-medium text-cyan-600">
                          {user?.name?.substring(0, 1).toUpperCase() || "U"}
                        </span>
                      </div>
                      <div className="ml-3">
                        <p className="text-sm font-medium text-gray-900">{user?.name}</p>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <div className="flex items-center">
                      <Mail className="h-4 w-4 text-gray-400 mr-2" />
                      {user?.email}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <code className="text-xs bg-gray-100 text-gray-800 px-2 py-1 rounded font-mono">
                        {user?.password}
                      </code>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <motion.button
                      onClick={() => handleDeleteUser(user?.id)}
                      className="text-red-600 hover:text-red-800 bg-red-50 hover:bg-red-100 p-2 rounded-lg transition-colors"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <Trash2 className="w-4 h-4" />
                    </motion.button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AddUser;