import React, { useState, useCallback, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useChatbotConfig } from '../contexts/ChatbotConfigContext';
import { customBranding, apiEndpoints, validationRegex } from '../config/config';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { 
  FileSpreadsheet, Cloud,
  Plus, 
  Loader2, Lock,
  Bookmark, BookmarkPlus,ChevronDown,
  Eye,
  GripVertical,
  AlertCircle,
  Pencil, FileText, ArrowDownCircle, Settings, MoveVertical, Edit, ToggleLeft
} from 'lucide-react';
import { toast } from 'react-toastify';
import Papa from 'papaparse';
import axios from 'axios';

const API_KEY = 'AIzaSyAqnFjM5HlIQ5RI_9Z5_dVkp23Knq3UhTo';

const inputTypes = [
  'text',
  'number',
  'email',
  'date',
];
// First, create a new DataPreviewAccordion component
const DataPreviewAccordion = ({ currentSheetState, isOpen, setIsOpen }) => {
  return (
    <div className="bg-white rounded-lg shadow-sm mb-6">
      <button 
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center justify-between p-4 hover:bg-gray-50"
      >
        <div className="flex items-center gap-2">
          <FileText className="text-blue-500" size={20} />
          <h2 className="text-lg font-semibold">Data Preview</h2>
        </div>
        <motion.div
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.2 }}
        >
          <ChevronDown size={20} />
        </motion.div>
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div 
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="overflow-hidden"
          >
            <div className="p-4 border-t">
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      {currentSheetState?.headers.map((header) => (
                        <th
                          key={header.id}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {header.name}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {currentSheetState?.data.slice(0, 5).map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {currentSheetState.headers.map((header) => (
                          <td
                            key={header.id}
                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                          >
                            {row[header.name]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};


const FormGenerator = () => {
      const { config } = useChatbotConfig();
  const [sheetUrl, setSheetUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [sheets, setSheets] = useState([]);
  const [currentSheetId, setCurrentSheetId] = useState(null);
  const [sheetStates, setSheetStates] = useState({});
  const [newHeaderName, setNewHeaderName] = useState('');
  const [sheetsLoaded, setSheetsLoaded] = useState(false);
  const [isValidating, setIsValidating] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [userToken, setUserToken] = useState(null);
  const [isDataPreviewOpen, setIsDataPreviewOpen] = useState(false);

  // Get current sheet's state
  const currentSheetState = currentSheetId ? sheetStates[currentSheetId] : null;

  const extractSheetId = (url) => {
    const regex = /\/spreadsheets\/d\/([a-zA-Z0-9-_]+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  useEffect(() => {
    const verifyUser = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const token  = params.get('userToken');

        if (!token) {
          toast.error('User token not found');
          setIsValidating(false);
          return;
        }
        setUserToken(token);
        localStorage.setItem('userToken', token);


        const response = await axios.get(`${apiEndpoints.form}/verify-user?userId=${token}`);
        
        if (response.data.status) {
          localStorage.setItem('user', JSON.stringify(response.data.user));
          setIsAuthorized(true);
        } else {
          toast.error('Not a valid user');
        }
      } catch (error) {
        console.error('User verification failed:', error);
        toast.error('Not a valid user');
      } finally {
        setIsValidating(false);
      }
    };
    verifyUser();
  }, []);

  
  const validateGoogleSheetUrl = useCallback((url) => {
    return url.includes('docs.google.com/spreadsheets') && extractSheetId(url);
  }, []);

  const fetchSheets = async (spreadsheetId) => {
    try {
      const response = await fetch(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}?key=${API_KEY}`
      );
      
      if (!response.ok) {
        const errorData = await response.json();
        console.error('API Error:', errorData);
        throw new Error(errorData.error?.message || 'Failed to fetch sheets');
      }
      
      const data = await response.json();
      return data.sheets.map(sheet => ({
        id: sheet.properties.sheetId,
        title: sheet.properties.title,
        index: sheet.properties.index
      }));
    } catch (error) {
      console.error('Error fetching sheets:', error);
      throw error;
    }
  };

  const fetchSheetData = async (spreadsheetId, sheetTitle) => {
    try {
      const exportUrl = `https://docs.google.com/spreadsheets/d/${spreadsheetId}/gviz/tq?tqx=out:csv&sheet=${encodeURIComponent(sheetTitle)}`;
      
      const response = await fetch(exportUrl);
      if (!response.ok) {
        throw new Error('Failed to fetch sheet data');
      }
      
      const csvText = await response.text();
      
      // Check if the CSV is completely empty or contains only whitespace/newlines
      if (!csvText.trim()) {
        return { data: [], isEmpty: true };
      }
      
      return new Promise((resolve, reject) => {
        Papa.parse(csvText, {
          header: true,
          skipEmptyLines: true,
          transformHeader: (header) => header.trim(),
          complete: (results) => {
            console.log('Parse results:', results); // Debug log
            
            // Check various empty conditions
            const isEmpty = 
              !results.data.length || // No data rows
              !Object.keys(results.data[0] || {}).length || // No columns
              results.data.every(row => // All rows are empty
                Object.values(row).every(cell => !cell || cell.trim() === '')
              );
            
            resolve({
              data: results.data,
              isEmpty: isEmpty,
              meta: results.meta
            });
          },
          error: (error) => {
            console.error('CSV Parse Error:', error);
            reject(error);
          }
        });
      });
    } catch (error) {
      console.error('Fetch Error:', error);
      throw error;
    }
  };

// Update the sheetStates structure to include an isEmpty flag
const handleLoadData = useCallback(async () => {
  if (!validateGoogleSheetUrl(sheetUrl)) {
    toast.error('Please enter a valid Google Sheets URL');
    return;
  }
 
  setLoading(true);
  try {
    // Reset all states
    setSheets([]);
    setCurrentSheetId(null);
    setSheetStates({});
    setNewHeaderName('');
    
    // Reset form field states
    const spreadsheetId = extractSheetId(sheetUrl);
    const sheetsList = await fetchSheets(spreadsheetId);
    setSheets(sheetsList);
    console.log('Sheets:', sheetsList);
    const newSheetStates = {};
    
    for (const sheet of sheetsList) {
      try {
        const result = await fetchSheetData(spreadsheetId, sheet.title);
        
        newSheetStates[sheet.id] = {
          isEmpty: result.isEmpty,
          sheetTitle: sheet.title,
          headers: result.isEmpty ? [] : 
            Object.keys(result.data[0] || {}).map((header, index) => ({
              id: `${sheet.id}-header-${index}`,
              name: header,
              type: 'text', // Reset to default type
              required: false, // Reset required state
              editName: undefined, // Reset edit state
              isEditing: false, // Reset editing mode
              sampleData: result.data.slice(0, 3).map(row => row[header])
            })),
          selectedHeaders: [], // Reset selected headers
          data: result.data || [],
          formSettings: {
            name: '',
            description: ''
          }
        };
      } catch (sheetError) {
        console.error(`Error loading sheet ${sheet.title}:`, sheetError);
        newSheetStates[sheet.id] = {
          isEmpty: true,
          sheetTitle: sheet.title,
          headers: [],
          selectedHeaders: [],
          data: [],
          error: sheetError.message,
          formSettings: {
            name: '',
            description: ''
          }
        };
      }
    }
 
    setSheetStates(newSheetStates);
    setSheetsLoaded(true); // Set to true when sheets load successfully
    toast.success('Sheets reloaded successfully!');
  } catch (error) {
    console.error('Error:', error);
    toast.error(error.message || 'Error loading sheets');
  } finally {
    setLoading(false);
  }
 }, [sheetUrl, validateGoogleSheetUrl]);


// Add EmptySheetMessage component
const EmptySheetMessage = ({ sheetTitle }) => (
  <div className="bg-white p-8 rounded-lg shadow-sm">
    <div className="flex flex-col items-center justify-center text-center space-y-4">
      <div className="p-3 bg-yellow-100 rounded-full">
        <AlertCircle className="w-8 h-8 text-yellow-500" />
      </div>
      <h3 className="text-lg font-semibold text-gray-900">Empty Sheet Detected</h3>
      <p className="text-gray-600 max-w-md">
        The sheet "{sheetTitle}" is currently empty. Please add some data to the sheet before generating a form.
      </p>
      <div className="mt-4 p-4 bg-gray-50 rounded-lg w-full max-w-md">
        <h4 className="font-medium text-gray-900 mb-2">Next Steps:</h4>
        <ol className="text-sm text-gray-600 list-decimal list-inside space-y-2">
          <li>Open your Google Sheet</li>
          <li>Add headers to the first row</li>
          <li>Add some data rows</li>
          <li>Return here and refresh</li>
        </ol>
      </div>
    </div>
  </div>
);

// Replace the existing FormSettings component in FormGenerator.js with:

const FormSettings = React.memo(({ currentSheetId, sheetStates, setSheetStates }) => {
  const [formName, setFormName] = useState(sheetStates[currentSheetId]?.formSettings?.name || '');
  const [formDescription, setFormDescription] = useState(sheetStates[currentSheetId]?.formSettings?.description || '');
  const [loginRequired, setLoginRequired] = useState(sheetStates[currentSheetId]?.formSettings?.loginRequired || 0);
 
  useEffect(() => {
    const currentFormSettings = sheetStates[currentSheetId]?.formSettings;
    setFormName(currentFormSettings?.name || '');
    setFormDescription(currentFormSettings?.description || '');
    setLoginRequired(currentFormSettings?.loginRequired || 0);
  }, [currentSheetId, sheetStates]);
 
  const handleBlur = (field, value) => {
    setSheetStates(prev => ({
      ...prev,
      [currentSheetId]: {
        ...prev[currentSheetId],
        formSettings: {
          ...prev[currentSheetId].formSettings,
          [field]: value
        }
      }
    }));
  };

  const handleLoginToggle = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setLoginRequired(newValue);
    handleBlur('loginRequired', newValue);
  };
 
  return (
    <div className="bg-white p-6 rounded-lg shadow-sm mb-6">
      <h3 className="text-lg font-semibold mb-4 flex items-center gap-2">
        <Settings size={20} className="text-blue-500" />
        Form Settings
      </h3>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Form Name
          </label>
          <input
            type="text"
            value={formName}
            onChange={(e) => setFormName(e.target.value)}
            onBlur={() => handleBlur('name', formName)}
            className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500"
            placeholder="Enter form name..."
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Description
          </label>
          <textarea
            value={formDescription}
            onChange={(e) => setFormDescription(e.target.value)}
            onBlur={() => handleBlur('description', formDescription)}
            className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500"
            rows="3"
            placeholder="Enter form description..."
          />
        </div>
        <div className="flex items-center justify-between py-2 border-t">
          <div className="flex items-center gap-2">
            <Lock className="w-5 h-5 text-gray-500" />
            <div>
              <h4 className="text-sm font-medium text-gray-900">Require Login</h4>
              <p className="text-sm text-gray-500">Users must log in to access this form</p>
            </div>
          </div>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={loginRequired === 1}
              onChange={handleLoginToggle}
              className="sr-only peer"
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
          </label>
        </div>
      </div>
    </div>
  );
});

  const handleSheetChange = useCallback((sheetId) => {
    setCurrentSheetId(sheetId);
  }, []);

  const handleAddCustomHeader = useCallback(() => {
    if (!currentSheetId) {
      toast.error('Please load a sheet first');
      return;
    }

    if (!newHeaderName.trim()) {
      toast.error('Please enter a header name');
      return;
    }

    setSheetStates(prev => {
      const currentSheet = prev[currentSheetId];
      const newHeader = {
        id: `${currentSheetId}-header-${currentSheet.headers.length}`,
        name: newHeaderName,
        type: 'text',
        required: false
      };

      return {
        ...prev,
        [currentSheetId]: {
          ...currentSheet,
          headers: [...currentSheet.headers, newHeader]
        }
      };
    });

    setNewHeaderName('');
    toast.success('Custom header added!');
  }, [currentSheetId, newHeaderName]);

  const handleHeaderTypeChange = useCallback((headerId, newType) => {
    if (!currentSheetId) return;

    setSheetStates(prev => {
      const currentSheet = prev[currentSheetId];
      const updatedHeaders = currentSheet.headers.map(header =>
        header.id === headerId ? { ...header, type: newType } : header
      );

      return {
        ...prev,
        [currentSheetId]: {
          ...currentSheet,
          headers: updatedHeaders
        }
      };
    });
  }, [currentSheetId]);

  const handleHeaderRequiredChange = useCallback((headerId) => {
    if (!currentSheetId) return;

    setSheetStates(prev => {
      const currentSheet = prev[currentSheetId];
      const updatedHeaders = currentSheet.headers.map(header =>
        header.id === headerId ? { ...header, required: !header.required } : header
      );

      return {
        ...prev,
        [currentSheetId]: {
          ...currentSheet,
          headers: updatedHeaders
        }
      };
    });
  }, [currentSheetId]);

  const handleDragEnd = useCallback((result) => {
    if (!result.destination || !currentSheetId) return;
    
    const { source, destination } = result;
    
    if (source.droppableId === destination.droppableId && 
        source.index === destination.index) {
      return;
    }

    setSheetStates(prev => {
      const currentSheet = prev[currentSheetId];
      const newHeaders = Array.from(currentSheet.headers);
      const [removed] = newHeaders.splice(source.index, 1);
      newHeaders.splice(destination.index, 0, removed);

      return {
        ...prev,
        [currentSheetId]: {
          ...currentSheet,
          headers: newHeaders
        }
      };
    });
  }, [currentSheetId]);


  const toCamelCase = (str) => {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  };

  // Add new function to create placeholder text
  const createPlaceholder = (label) => {
    return `Enter your ${label.toLowerCase()}`;
  };

  // Add function to prepare form data for API
  const prepareFormData = useCallback(() => {
    if (!currentSheetState || !currentSheetId || !userToken) return null;

    const selectedFields = currentSheetState.headers
      .filter(header => currentSheetState.selectedHeaders.includes(header.id))
      .map(header => ({
        label: header.name,
        type: header.type,
        required: header.required,
        // name: header.name,
        placeHolder: createPlaceholder(header.name)
      }));
      const formSettings = currentSheetState.formSettings || {};

    return {
      userId: userToken,
      googleSheetsUrl: sheetUrl,
      sheetName: currentSheetState.sheetTitle,
      name: formSettings.name,  
      description: formSettings.description,
      settings: {
        loginRequired: formSettings.loginRequired || 0
      },
      jdata: selectedFields,
      dueDate: "2025-01-01"
    };
  }, [currentSheetState, currentSheetId, sheetUrl, userToken]);


// Modify handleCreateForm to include API call
  const handleCreateForm = useCallback(async () => {
    if (!currentSheetState) {
      toast.error('Please select a sheet first');
      return;
    }

    if (currentSheetState.selectedHeaders.length === 0) {
      toast.error('Please select fields for the form');
      return;
    }

    const formData = prepareFormData();
    if (!formData) {
      toast.error('Error preparing form data');
      return;
    }

    try {
      const response = await axios.post(
     apiEndpoints.form,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            // 'clientToken': `Bearer ${config.accessToken}`
          }
        }
      );

      if (response.status === 201) {
        toast.success('Form created successfully!');
        // Open the link in the existing tab
        window.location.href = 'https://bolofy.com/client/forms/view';
      }
} catch (error) {
  console.error('Error creating form:', error);
  toast.error(error.response?.data?.message || 'Error creating form');
}
}, [currentSheetState, prepareFormData]);

  const handleToggleHeaderSelection = useCallback((headerId) => {
    if (!currentSheetId) return;

    setSheetStates(prev => {
      const currentSheet = prev[currentSheetId];
      const isSelected = currentSheet.selectedHeaders.includes(headerId);
      
      return {
        ...prev,
        [currentSheetId]: {
          ...currentSheet,
          selectedHeaders: isSelected
            ? currentSheet.selectedHeaders.filter(id => id !== headerId)
            : [...currentSheet.selectedHeaders, headerId]
        }
      };
    });
  }, [currentSheetId]);

  if (isValidating) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="flex flex-col items-center space-y-4">
          <motion.div
            animate={{ rotate: 360 }}
            transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
          >
            <div className="w-12 h-12 border-4 border-t-blue-500 border-b-blue-500 rounded-full" />
          </motion.div>
          <p className="text-gray-600">Verifying user...</p>
        </div>
      </div>
    );
  }
  if (!isAuthorized) {
    return (
      <div className="min-h-screen flex items-center justify-center p-4">
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full text-center">
          <AlertCircle className="w-16 h-16 text-red-500 mx-auto mb-4" />
          <h2 className="text-2xl font-bold text-gray-800 mb-2">Access Denied</h2>
          <p className="text-gray-600">You are not authorized to access this page.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 p-8">
   

    <div className="max-w-7xl mx-auto">
    <div className="flex items-center justify-between mb-8">
 <div className="flex items-center space-x-3">
   
   <img
      src={config.logo_url}
      alt={`${customBranding.name} Logo`}
      className="h-10 w-autoobject-contain"
     
      />
   <div>
     <h1 className="text-2xl font-bold text-gray-900">
       Bolofy Form Generator
     </h1>
     <p className="text-sm text-gray-500">
       Convert Google Sheets to Dynamic Forms
     </p>
   </div>
 </div>
 
 <div className="flex items-center space-x-4">

   <div className="text-right font-medium">
    
     {/* <div className="text-blue-500 font-bold">Bolofy</div> */}
     Powered by <span style={{ color: config.color }}>{`${config.name} `}</span>
   </div>
 </div>
</div>  
      {/* <h1 className="text-3xl font-bold text-gray-800 mb-8">Dynamic Form Generator</h1> */}

      {/* Sequence Section */}
      <div className="bg-white p-6 rounded-lg shadow-sm mb-8">
        
        <div className="space-y-4">
          {/* URL Input */}
          <div className="flex gap-4 items-center">
            <div className="flex-1">
              <input
                type="text"
                value={sheetUrl}
                onChange={(e) => setSheetUrl(e.target.value)}
                placeholder="Enter Google Sheet URL"
                className="w-full p-3 border rounded-lg focus:ring-2 outline-none focus:ring-blue-500"
              />
            </div>
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={handleLoadData}
              disabled={loading}
              className="bg-blue-500 text-white px-6 py-3 rounded-lg flex items-center gap-2 disabled:opacity-50"
            >
              {loading ? <Loader2 className="animate-spin" size={20} /> : <FileSpreadsheet size={20} />}
              Load Data
            </motion.button>
          </div>


          {/* Sheet Selector */}
          {sheets.length > 0 && (
  <div className="flex items-center gap-4">
    <select
      value={currentSheetId || ''}
      onChange={(e) => handleSheetChange(e.target.value)}
      className="p-2 border rounded-lg"
    >
      <option value="">Select a sheet</option>
      {sheets.map(sheet => (
        <option key={sheet.id} value={sheet.id}>
          {sheet.title} {sheetStates[sheet.id]?.isEmpty ? ' (Empty)' : ''}
        </option>
      ))}
    </select>
    {currentSheetState?.isEmpty && (
      <span className="text-yellow-600 text-sm flex items-center gap-1">
        <AlertCircle className="w-4 h-4" />
        Current sheet is empty
      </span>
    )}
  </div>
)}
 </div>
      </div>

       {/* Data Preview Accordion */}
       {currentSheetState && !currentSheetState.isEmpty && (
          <DataPreviewAccordion 
            currentSheetState={currentSheetState}
            isOpen={isDataPreviewOpen}
            setIsOpen={setIsDataPreviewOpen}
          />
        )}

      {!sheetsLoaded && (
  
  <motion.div 
   initial={{ opacity: 0, y: 20 }}
   animate={{ opacity: 1, y: 0 }}
   transition={{ duration: 0.6 }}
   className="mt-8 mb-12 text-center"
  >
   <motion.div 
     className="flex justify-center mb-4"
     animate={{ scale: [1, 1.1, 1] }}
     transition={{ duration: 1, repeat: Infinity, repeatDelay: 3 }}
   >
     <FileText size={48} className="text-blue-500" />
   </motion.div>
  
   <h2 className="text-2xl font-bold text-gray-800 mb-4">
     Welcome to Dynamic Form Generator
   </h2>
  
   <motion.div 
     className="max-w-2xl mx-auto bg-white p-6 rounded-lg shadow-sm"
     initial={{ opacity: 0 }}
     animate={{ opacity: 1 }}
     transition={{ delay: 0.3 }}
   >
     <div className="flex items-start space-x-4 mb-4">
       <ArrowDownCircle className="text-blue-500 mt-1 flex-shrink-0" />
       <p className="text-gray-600 text-left">
         Paste your Google Sheet URL above to instantly convert it into a customizable form. You can:
       </p>
     </div>
  
     <div className="grid grid-cols-2 gap-4 text-left text-gray-600">
       <div className="flex items-center space-x-2">
         <Settings size={20} className="text-green-500" />
         <span>Customize field types</span>
       </div>
       <div className="flex items-center space-x-2">
         <MoveVertical size={20} className="text-green-500" />
         <span>Reorder fields</span>
       </div>
       <div className="flex items-center space-x-2">
         <Edit size={20} className="text-green-500" />
         <span>Edit field names</span>
       </div>
       <div className="flex items-center space-x-2">
         <ToggleLeft size={20} className="text-green-500" />
         <span>Toggle required fields</span>
       </div>
     </div>
     <div className="flex justify-center mt-8 bg-white text-gray-400 text-center text-xs">
     Powered by <span style={{ color: config.color, marginLeft: 5 }}>{`${config.name} `}</span>
      </div>
   </motion.div>
  </motion.div>
  )}

      {currentSheetState && !currentSheetState.isEmpty && (
        <div className="grid grid-cols-2 gap-8">
          {/* Form Fields Section */}
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h2 className="text-xl font-semibold mb-6">Form Fields</h2>
            
            {/* Add Custom Header */}
            <div className="mb-6 flex gap-2">
              <input
                type="text"
                value={newHeaderName}
                onChange={(e) => setNewHeaderName(e.target.value)}
                placeholder="Add custom field"
                className="flex-1 p-2 border rounded-lg"
              />
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={handleAddCustomHeader}
                className="bg-green-500 text-white p-2 rounded-lg"
              >
                <Plus size={20} />
              </motion.button>
            </div>

            {/* Headers List */}
            <DragDropContext onDragEnd={handleDragEnd}>
    <Droppable droppableId="headers-list">
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-3">
          <AnimatePresence>
            {currentSheetState.headers.map((header, index) => (
              <Draggable key={header.id} draggableId={header.id} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={`p-4 border rounded-lg ${
                      currentSheetState.selectedHeaders.includes(header.id)
                        ? 'border-blue-500 bg-blue-50'
                        : 'border-gray-200'
                    }`}
                  >
                    <div className="flex items-center gap-4">
                      <div {...provided.dragHandleProps}>
                        <GripVertical className="text-gray-400" />
                      </div>
                      <div className="flex-1">
                        {header.isEditing ? (
                          <input
                            type="text"
                            value={header.editName ?? header.name}
                            onChange={(e) => {
                              setSheetStates(prev => ({
                                ...prev,
                                [currentSheetId]: {
                                  ...prev[currentSheetId],
                                  headers: prev[currentSheetId].headers.map(h => 
                                    h.id === header.id ? { ...h, editName: e.target.value } : h
                                  )
                                }
                              }));
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === 'Escape') {
                                const newName = header.editName?.trim() || header.name;
                                setSheetStates(prev => ({
                                  ...prev,
                                  [currentSheetId]: {
                                    ...prev[currentSheetId],
                                    headers: prev[currentSheetId].headers.map(h => 
                                      h.id === header.id ? {
                                        ...h,
                                        name: newName,
                                        isEditing: false,
                                        editName: undefined
                                      } : h
                                    )
                                  }
                                }));
                              }
                            }}
                            onBlur={() => {
                              const newName = header.editName?.trim() || header.name;
                              setSheetStates(prev => ({
                                ...prev,
                                [currentSheetId]: {
                                  ...prev[currentSheetId],
                                  headers: prev[currentSheetId].headers.map(h => 
                                    h.id === header.id ? {
                                      ...h,
                                      name: newName,
                                      isEditing: false,
                                      editName: undefined
                                    } : h
                                  )
                                }
                              }));
                            }}
                            className="w-full p-1 border rounded"
                            autoFocus
                          />
                        ) : (
                          <div className="flex items-center gap-2">
                            <div className="font-medium">{header.name}</div>
                            <button
                              onClick={() => {
                                setSheetStates(prev => ({
                                  ...prev,
                                  [currentSheetId]: {
                                    ...prev[currentSheetId],
                                    headers: prev[currentSheetId].headers.map(h => 
                                      h.id === header.id ? { ...h, isEditing: true, editName: h.name } : h
                                    )
                                  }
                                }));
                              }}
                              className="p-1 hover:bg-gray-100 rounded"
                            >
                              <Pencil size={16} className="text-gray-500" />
                            </button>
                          </div>
                        )}
                      </div>
                      <select
                        value={header.type}
                        onChange={(e) => handleHeaderTypeChange(header.id, e.target.value)}
                        className="p-2 border rounded"
                      >
                        {inputTypes.map(type => (
                          <option key={type} value={type}>
                            {type.charAt(0).toUpperCase() + type.slice(1)}
                          </option>
                        ))}
                      </select>
                      <div className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          checked={header.required}
                          onChange={() => handleHeaderRequiredChange(header.id)}
                          className="w-4 h-4"
                        />
                        <span className="text-sm text-gray-600">Required</span>
                      </div>
                      <div className="flex items-center">
                            <label className="relative inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            checked={currentSheetState.selectedHeaders.includes(header.id)}
                            onChange={() => handleToggleHeaderSelection(header.id)}
                            className="sr-only peer"
                          />
                          <div className="w-7 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all peer-checked:bg-blue-500"></div>
                        </label>
                      </div>  
                  </div>
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </AnimatePresence>
      </div>
    )}
  </Droppable>
  </DragDropContext>
          </div>

              <div>
                <FormSettings 
                  currentSheetId={currentSheetId}
                  sheetStates={sheetStates}
                  setSheetStates={setSheetStates}
                />

          {/* Form Preview Section */}
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold">Form Preview</h2>
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={handleCreateForm}
                className="bg-green-500 text-white px-4 py-2 rounded-lg"
                disabled={currentSheetState.headers.length === 0}
              >
                Create Form
              </motion.button>
            </div>

            <div className="border-2 border-dashed border-gray-200 rounded-lg p-6">
              {currentSheetState.selectedHeaders.length > 0 ? (
                <div className="space-y-4">
                  {currentSheetState.headers
                    .filter(header => currentSheetState.selectedHeaders.includes(header.id))
                    .map(header => (
                      <div key={header.id} className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          {header.name}
                          {header.required && <span className="text-red-500 ml-1">*</span>}
                        </label>
                        {header.type === 'textarea' ? (
                          <textarea
                            className="w-full p-2 border rounded"
                            placeholder={`Enter ${header.name.toLowerCase()}`}
                            required={header.required}
                          />
                        )  : header.type === 'checkbox' ? (
                          <div className="flex items-center">
                            <input
                              type="checkbox"
                              className="w-4 h-4 mr-2"
                              required={header.required}
                            />
                            <span className="text-sm text-gray-700">{header.name}</span>
                          </div>
                        ) : (
                          <input
                            type={header.type}
                            className="w-full p-2 border rounded"
                            placeholder={`Enter ${header.name.toLowerCase()}`}
                            required={header.required}
                          />
                        )}
                      </div>
                    ))}
                </div>
              ) : (
                <div className="text-center py-8 text-gray-500">
                  <Eye className="mx-auto mb-2" size={24} />
                  Select fields to preview
                </div>
              )}
            </div>
          </div>

          {/* Data Preview Section */}
          {/* <div className="bg-white p-6 rounded-lg shadow-sm col-span-2">
            <h2 className="text-xl font-semibold mb-6">Data Preview</h2>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {currentSheetState.headers.map((header) => (
                      <th
                        key={header.id}
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {header.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {currentSheetState.data.slice(0, 5).map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {currentSheetState.headers.map((header) => (
                        <td
                          key={header.id}
                          className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                        >
                          {row[header.name]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div> */}
        </div>
        </div>
      )}

      {currentSheetState?.isEmpty && (
        <EmptySheetMessage 
          sheetTitle={currentSheetState.sheetTitle}
          error={currentSheetState.error}
        />
      )}
    </div>
  </div>
);
};

export default FormGenerator;