import React, { useState, useEffect, useRef, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FileText,Plus,Check,AlertCircle,X, Link,PenTool,ArrowLeft , ChevronRight, CheckCircle, Lock, Trash2, RefreshCw, Edit } from 'lucide-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { apiEndpoints } from '../config/config';
import FormResponsesTable from './FormResponsesTable';
import AddUser from './AddUser';

// Google API constants
const CLIENT_ID = "930463180056-ktpn9j16f3njjqb229012qah5jrtf5gj.apps.googleusercontent.com";
// Update the SCOPES constant at the top of the file
const SCOPES = "https://www.googleapis.com/auth/documents.readonly https://www.googleapis.com/auth/drive.readonly";


const DeleteConfirmationDialog = ({ isOpen, templateName, onConfirm, onCancel }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={onCancel}
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className="bg-white rounded-xl shadow-xl p-6 max-w-md w-full mx-4"
            onClick={e => e.stopPropagation()}
          >
            <div className="flex items-center gap-4 mb-4 pb-2 border-b border-gray-100">
              <div className="p-2 bg-red-50 rounded-full">
                <AlertCircle className="h-6 w-6 text-red-500" />
              </div>
              <h3 className="text-lg font-semibold text-gray-900">Delete Template?</h3>
              <button 
                onClick={onCancel}
                className="ml-auto p-1 hover:bg-gray-100 rounded-full transition-colors"
              >
                <X className="h-5 w-5 text-gray-500" />
              </button>
            </div>
            
            <p className="text-gray-600 mb-5">
              Are you sure you want to delete "<span className="font-medium">{templateName}</span>"? This action cannot be undone and will permanently remove this template.
            </p>
            
            <div className="flex justify-end gap-3 mt-6">
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={onCancel}
                className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 bg-white hover:bg-gray-50 font-medium"
              >
                Cancel
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={onConfirm}
                className="px-4 py-2 bg-gradient-to-r from-red-500 to-red-600 text-white rounded-lg hover:shadow-md font-medium flex items-center gap-2"
              >
                <Trash2 size={16} />
                Delete Template
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};


const UpdateForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({});
  const [formFields, setFormFields] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formResponses, setFormResponses] = useState([]);
  const [editMode, setEditMode] = useState({});
  const [errors, setErrors] = useState({});
  const [newTemplateUrl, setNewTemplateUrl] = useState('');
  const [newTemplateName, setNewTemplateName] = useState('');
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [tokenClient, setTokenClient] = useState(null);
  const [fetchingDoc, setFetchingDoc] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    isOpen: false,
    templateId: null,
    templateName: ''
  });

  // Wrapper function to show confirmation before deleting
  const confirmTemplateDelete = (template) => {
    setDeleteConfirmation({
      isOpen: true,
      templateId: template.id,
      templateName: template.name
    });
  };

  const inputRef = useRef(null);


  const handleGoBack = () => {
    window.history.back();
  };

 // Initialize Google Identity Services with improved persistence
useEffect(() => {
  const initializeGAPI = () => {
    // Check if we already have a token in localStorage
    const savedToken = localStorage.getItem('googleAuthToken');
    const tokenExpiry = localStorage.getItem('googleAuthTokenExpiry');
    const currentTime = new Date().getTime();
    
    // If we have a valid non-expired token, use it
    if (savedToken && tokenExpiry && currentTime < parseInt(tokenExpiry)) {
      setAccessToken(savedToken);
      setIsSignedIn(true);
      console.log("Using saved Google auth token");
      return;
    }
    
    const client = window.google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: (response) => {
        if (response.access_token) {
          // Store token with expiration (token typically lasts 1 hour)
          // const expiryTime = new Date().getTime() + (response.expires_in * 1000);
            const expiryTime = new Date().getTime() + (30 * 24 * 60 * 60 * 1000);
          localStorage.setItem('googleAuthToken', response.access_token);
          localStorage.setItem('googleAuthTokenExpiry', expiryTime.toString());
          
          setAccessToken(response.access_token);
          setIsSignedIn(true);
          toast.success("Signed in to Google successfully");
        } else if (response.error) {
          console.error("Token Error:", response.error);
          toast.error(`Google sign-in failed: ${response.error}`);
        }
      },
      prompt: 'consent'  // Set to 'none' for silent token refresh if possible
    });
    setTokenClient(client);
  };

  if (window.google) {
    initializeGAPI();
  } else {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.onload = initializeGAPI;
    document.body.appendChild(script);
  }
}, []);

// Add a refreshToken function to handle token expiration
const refreshToken = useCallback(() => {
  if (tokenClient) {
    // Clear existing token data
    localStorage.removeItem('googleAuthToken');
    localStorage.removeItem('googleAuthTokenExpiry');
    
    // Request a new token
    tokenClient.requestAccessToken({
      prompt: 'none' // Try silent refresh first
    });
  }
}, [tokenClient]);



  useEffect(() => {
    const fetchFormData = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const userToken = params.get('userToken');
        const formId = params.get('formId');

        if (!userToken || !formId) {
          toast.error('Missing required parameters');
          return;
        }

        const response = await axios.get(`${apiEndpoints.form}/show?userId=${userToken}&formId=${formId}`);
        const { form: formDataResponse, formResponse, formUsers, formTemplate } = response.data;
        
        const templates = Array.isArray(formTemplate) ? formTemplate : formTemplate ? [formTemplate] : [];
        console.log("Form Templates:", templates);
        
        const parsedJdata = typeof formDataResponse.jdata === 'string' 
          ? JSON.parse(formDataResponse.jdata) 
          : formDataResponse.jdata;

          const settings = typeof formDataResponse.settings === 'string'
          ? JSON.parse(formDataResponse.settings)
          : formDataResponse.settings || { loginRequired: 0, templateEnabled: 0, templateUrl: '',  templates: [], signatureEnabled: 0,  signatureWidth: 400,
            signatureHeight: 200,
            gdprEnabled: 0  };

        const initialFields = Array.isArray(parsedJdata) 
          ? parsedJdata.map((field, index) => ({
              ...field,
              originalIndex: index,
              isRemoved: field.isRemoved || false
            }))
          : [];
        
        setFormData({
          ...formDataResponse,
          jdata: initialFields,
          settings: settings,
          formUsers: formUsers || [],
          formTemplates: templates  // Store the entire templates array
        });
        console.log("Form Template IDs:", templates.map(t => t.id)); // Logs all IDs in the array

        setFormFields(initialFields);
        setFormResponses(formResponse || []);
      } catch (error) {
        console.error('Error fetching form:', error);
        toast.error('Failed to load form');
      } finally {
        setLoading(false);
      }
    };

    fetchFormData();
  }, []);


  const validateGoogleDocsUrl = (url) => {
    const pattern = /^https:\/\/docs\.google\.com\/(document|spreadsheets)\/d\/[-\w]{25,}/i;
    return pattern.test(url);
  };

   // Function to extract document ID from Google Docs URL
   const extractDocumentId = (url) => {
    const pattern = /\/d\/([-\w]{25,})/;
    const match = url.match(pattern);
    return match ? match[1] : null;
  };

  // Handle sign-in button click
  const handleSignIn = (e) => {
    e.preventDefault();
    if (tokenClient) {
      tokenClient.requestAccessToken();
    } else {
      toast.error("Google API not initialized. Please try again.");
    }
  };

  const handleGoogleLogout = (e) => {
    e.preventDefault();
    // Clear the stored tokens
    localStorage.removeItem('googleAuthToken');
    localStorage.removeItem('googleAuthTokenExpiry');
    
    // Update state
    setAccessToken(null);
    setIsSignedIn(false);

    
    toast.success("Logged out from Google successfully");
  };

// Update fetchGoogleDocContent to handle token expiration
// Update the fetchGoogleDocContent function to return HTML content
const fetchGoogleDocContent = async (url) => {
  if (!validateGoogleDocsUrl(url)) {
    toast.error('Please enter a valid Google Docs URL');
    return null;
  }

  if (!accessToken) {
    toast.info("Please sign in to Google first");
    return null;
  }

  setFetchingDoc(true);
  try {
    const documentId = extractDocumentId(url);
    if (!documentId) {
      toast.error("Could not extract document ID from URL");
      return null;
    }

    const response = await fetch(
      `https://docs.googleapis.com/v1/documents/${documentId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    // Handle 401 Unauthorized (expired token)
    if (response.status === 401) {
      toast.info("Google session expired. Refreshing authentication...");
      refreshToken();
      return null;
    }

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        `Failed to fetch document: ${errorData.error?.message || response.statusText}`
      );
    }

    const data = await response.json();
    
    // Convert Google Docs content to HTML
    let htmlContent = "<html><head><meta content='text/html; charset=UTF-8' http-equiv='content-type'>";
    htmlContent += "<style type='text/css'>ol{margin:0;padding:0}table td,table th{padding:0} .c0{color:#000000;font-weight:400;text-decoration:none;vertical-align:baseline;font-size:11pt;font-family:'Arial';font-style:normal} .c1{padding-top:0pt;padding-bottom:0pt;line-height:1.15;orphans:2;widows:2;text-align:left} .c2{height:11pt}</style></head>";
    htmlContent += "<body class='doc-content'>";
    
    if (data.body && data.body.content) {
      // Process each structural element in the document
      data.body.content.forEach(element => {
        if (element.paragraph && element.paragraph.elements) {
          htmlContent += "<p class='c1'>";
          
          element.paragraph.elements.forEach(paragraphElement => {
            if (paragraphElement.textRun && paragraphElement.textRun.content) {
              const text = paragraphElement.textRun.content;
              const style = paragraphElement.textRun.textStyle || {};
              
              // Apply basic styling
              let spanClass = "c0";
              let styleAttr = "";
              
              if (style.bold) styleAttr += "font-weight:bold;";
              if (style.italic) styleAttr += "font-style:italic;";
              if (style.underline) styleAttr += "text-decoration:underline;";
              
              htmlContent += `<span class='${spanClass}' ${styleAttr ? `style='${styleAttr}'` : ''}>${text}</span>`;
            }
          });
          
          htmlContent += "</p>";
        } else if (element.table) {
          // Handle tables if needed
          htmlContent += "<table>";
          // Table processing code would go here
          htmlContent += "</table>";
        }
      });
    }
    
    htmlContent += "</body></html>";
    console.log("HTML Content:", htmlContent);
    toast.success("Document content fetched and converted to HTML");
    return {
      text: data.title || "Untitled Document",
      html: htmlContent
    };
  } catch (error) {
    console.error("Error fetching Google Doc:", error);
    toast.error(error.message);
    return null;
  } finally {
    setFetchingDoc(false);
  }
};

// Also update exportToPdf function to handle token expiration
const exportToPdf = async (documentId, fileName = 'document.pdf') => {
  if (!accessToken) {
    toast.error("Please sign in to Google first");
    return;
  }

  try {
    toast.info("Exporting document to PDF...");
    
    const response = await fetch(
      `https://www.googleapis.com/drive/v3/files/${documentId}/export?mimeType=application/pdf`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    // Handle 401 Unauthorized (expired token)
    if (response.status === 401) {
      toast.info("Google session expired. Refreshing authentication...");
      refreshToken();
      return;
    }

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        `Failed to export document as PDF: ${errorData.error?.message || response.statusText}`
      );
    }

    // Get the PDF blob
    const pdfBlob = await response.blob();
    
    // Create a download link and trigger the download
    const url = window.URL.createObjectURL(pdfBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
    
    toast.success("PDF exported successfully");
  } catch (error) {
    console.error("Error exporting to PDF:", error);
    toast.error(error.message);
  }
};

const handleAddTemplate = async () => {
  if (!newTemplateUrl.trim() || !newTemplateName.trim()) {
    toast.error('Both name and URL are required');
    return;
  }

  if (!validateGoogleDocsUrl(newTemplateUrl)) {
    toast.error('Please enter a valid Google Docs URL');
    return;
  }

  if (!isSignedIn) {
    toast.info("You need to sign in to Google first");
    handleSignIn(new Event('click'));
    return;
  }

  // Fetch document content
  const content = await fetchGoogleDocContent(newTemplateUrl);
  if (!content) return;

  try {
    const params = new URLSearchParams(window.location.search);
    const userId = params.get('userToken');
    const formId = params.get('formId');
    const templateFormId = formData.responseUrl || formId;

    // Make the API call to add the template
    const response = await axios.post(`${apiEndpoints.form}/template?userId=${userId}&formId=${templateFormId}`, {
      googleDocsUrl: newTemplateUrl.trim(),
      content: content.html,
      name: newTemplateName.trim()
    });

    console.log("Template Add Response:", response.data);

    // Extract template from the response - it's nested in formTemplate property
    const apiTemplate = response.data?.formTemplate;
    
    if (!apiTemplate) {
      console.error("No template returned from API:", response.data);
      toast.warning("Template added but details not returned - refresh may be required");
      return;
    }

    // Create a template object for the UI that matches the structure expected in settings.templates
    const uiTemplate = {
      id: apiTemplate.id,
      name: apiTemplate.name,
      url: apiTemplate.googleDocsUrl,
      content: apiTemplate.content
    };

    // Update both the API templates array and the UI templates array
    setFormData(prev => ({
      ...prev,
      // Add to formTemplates array (API structure)
      formTemplates: [...(prev.formTemplates || []), apiTemplate],
      settings: {
        ...prev.settings,
        // Add to settings.templates array (UI structure)
        templates: [...(prev.settings?.templates || []), uiTemplate],
        templateEnabled: 1
      }
    }));

    setNewTemplateUrl('');
    setNewTemplateName('');
    inputRef.current?.focus();
    
    toast.success("Template added successfully");
  } catch (error) {
    console.error("Error adding template:", error);
    toast.error("Failed to add template: " + (error.response?.data?.message || error.message));
  }
};

  // const handleEditTemplate = async (template) => {
  //   if (!template.name.trim()) {
  //     toast.error('Template name cannot be empty');
  //     return;
  //   }
  
  //   if (!validateGoogleDocsUrl(template.url)) {
  //     toast.error('Please enter a valid Google Docs URL');
  //     return;
  //   }
  
  //   console.log("📌 Template Passed to Edit Function:", template);
  //   console.log("📌 Searching for Template ID:", template.id);
  //   console.log("📌 Searching for Template URL:", template.url);
  //   console.log("📌 Available Templates:", formData.formTemplates);
  //   console.log("📌 Available Template IDs:", formData.formTemplates.map(t => t.id));
  //   console.log("📌 Available Template URLs:", formData.formTemplates.map(t => t.googleDocsUrl));
  
  //   if (!Array.isArray(formData.formTemplates) || formData.formTemplates.length === 0) {
  //     console.error("❌ formData.formTemplates is empty or not an array!");
  //     toast.error("No templates found in form data");
  //     return;
  //   }
  
  //   const templateId = template.id;
  //   console.log(`🔍 Template ID: ${templateId}`);
  
  //   let templateToUpdate = formData.formTemplates.find(t => Number(t.id) === templateId);
  
  //   if (!templateToUpdate) {
  //     console.warn(`❌ Template ID ${templateId} not found, trying URL match...`);
  //     templateToUpdate = formData.formTemplates.find(t => t.googleDocsUrl === template.url);
  //   }
  
  //   if (!templateToUpdate) {
  //     console.error("❌ No matching template found!");
  //     toast.error("Template not found");
  //     return;
  //   }
  
  //   console.log("✅ Matched Template:", templateToUpdate);
  
  //   let updatedTemplate = { ...template };
  
  //   const originalTemplate = formData.settings?.templates.find(t => t.id === templateToUpdate.id);
  //   if (originalTemplate?.googleDocsUrl !== template.url) {
  //     if (!isSignedIn) {
  //       toast.info("You need to sign in to Google first");
  //       handleSignIn();
  //       return;
  //     }
      
  //     const content = await fetchGoogleDocContent(template.url);
  //     if (!content) return;
      
  //     updatedTemplate.content = content;
  //   }
  
  //   try {
  //     const params = new URLSearchParams(window.location.search);
  //     const userId = params.get('userToken');
  //     const templateFormId = formData.responseUrl;
  
  //     console.log(`📤 Sending API Request to Update Template ID: ${templateToUpdate.id}`);
  
  //     await axios.put(`${apiEndpoints.form}/template?userId=${userId}&formId=${templateFormId}`, {
  //       googleDocsUrl: updatedTemplate.url,
  //       content: updatedTemplate.content,
  //       name: updatedTemplate.name,
  //       templateId: templateToUpdate.id,
  //     });
  
  //     toast.success("Template updated successfully");
  
  //     const updatedTemplates = (formData.settings?.templates || []).map(t =>
  //       t.id === templateToUpdate.id ? updatedTemplate : t
  //     );
  
  //     setFormData(prev => ({
  //       ...prev,
  //       settings: {
  //         ...prev.settings,
  //         templates: updatedTemplates
  //       }
  //     }));
  
  //     setEditMode(prev => ({ ...prev, [templateToUpdate.id]: false }));
  //   } catch (error) {
  //     console.error("❌ Error updating template:", error);
  //     toast.error("Failed to update template");
  //   }
  // };

  const handleEditTemplate = async (template) => {
    if (!template.name.trim()) {
      toast.error('Template name cannot be empty');
      return;
    }
  
    if (!validateGoogleDocsUrl(template.url)) {
      toast.error('Please enter a valid Google Docs URL');
      return;
    }
  
    console.log("📌 Template Passed to Edit Function:", template);
    
    // Find the corresponding API template in formTemplates
    const apiTemplate = formData.formTemplates.find(t => t.id === template.id);
    
    if (!apiTemplate) {
      console.error("❌ No matching API template found!");
      toast.error("Template not found in API data");
      return;
    }
    
    console.log("✅ Matched API Template:", apiTemplate);
    
    // Check if URL changed - if so, fetch new content
    let content = apiTemplate.content;
    if (apiTemplate.googleDocsUrl !== template.url) {
      if (!isSignedIn) {
        toast.info("You need to sign in to Google first");
        handleSignIn(new Event('click'));
        return;
      }
      
      const newContent = await fetchGoogleDocContent(template.url);
      if (!newContent) return;
      content = newContent;
    }
  
    try {
      const params = new URLSearchParams(window.location.search);
      const userId = params.get('userToken');
      // const formId = params.get('formId');
      const templateFormId = formData.responseUrl;
  
      console.log(`📤 Sending API Request to Update Template ID: ${apiTemplate.id}`);
  
      // Make API request to update template
      const response = await axios.put(`${apiEndpoints.form}/template?userId=${userId}&formId=${templateFormId}`, {
        googleDocsUrl: template.url,
        content: content,
        name: template.name,
        templateId: apiTemplate.id
      });
  
      console.log("Template Update Response:", response.data);
  
      // Get updated template from response
      const updatedApiTemplate = response.data?.formTemplate || response.data;
      
      if (!updatedApiTemplate) {
        console.error("No template returned in update response");
        toast.warning("Template updated but details not returned");
      }
      
      // Create updated template objects for both API and UI
      const updatedTemplate = {
        ...apiTemplate,
        name: template.name,
        googleDocsUrl: template.url,
        content: content
      };
      
      const updatedUiTemplate = {
        id: template.id,
        name: template.name,
        url: template.url,
        content: content
      };
  
      // Update both template collections in state
      setFormData(prev => ({
        ...prev,
        // Update formTemplates (API structure)
        formTemplates: prev.formTemplates.map(t => 
          t.id === template.id ? updatedTemplate : t
        ),
        settings: {
          ...prev.settings,
          // Update settings.templates (UI structure)
          templates: prev.settings.templates.map(t =>
            t.id === template.id ? updatedUiTemplate : t
          )
        }
      }));
  
      setEditMode(prev => ({ ...prev, [template.id]: false }));
      toast.success("Template updated successfully");
    } catch (error) {
      console.error("❌ Error updating template:", error);
      toast.error("Failed to update template: " + (error.response?.data?.message || error.message));
    }
  };


  const handleDeleteTemplate = async (templateId) => {
    try {
      const params = new URLSearchParams(window.location.search);
      const userId = params.get('userToken');
      // const formId = params.get('formId');
      const templateFormId = formData.responseUrl;
      console.log("📌 Template ID to Delete from UI:", templateId);
      
      // Find the template in UI templates (in settings.templates)
      const uiTemplate = formData.settings?.templates?.find(t => t.id === templateId);
      
      if (!uiTemplate) {
        toast.error("Template not found in UI data");
        return;
      }
      
      // Find the matching API template in formTemplates
      const apiTemplate = formData.formTemplates?.find(t => t.id === templateId);
      
      if (!apiTemplate) {
        toast.error("Template not found in API data");
        console.error("Available API templates:", formData.formTemplates);
        return;
      }
      
      console.log("Using API template ID:", apiTemplate.id);
      
      // API call to delete the template
      await axios.delete(`${apiEndpoints.form}/template`, {
        params: {
          userId: userId,
          formId: templateFormId,
          templateId: apiTemplate.id
        }
      });
      
      // Update local state to remove the template
      setFormData(prev => ({
        ...prev,
        // Update formTemplates (API structure)
        formTemplates: prev.formTemplates.filter(t => t.id !== templateId),
        settings: {
          ...prev.settings,
          // Update settings.templates (UI structure)
          templates: prev.settings.templates.filter(t => t.id !== templateId),
          // Disable template functionality if no templates remain
          templateEnabled: prev.formTemplates.length > 1 ? 1 : 0
        }
      }));
      
      toast.success("Template deleted successfully!");
    } catch (error) {
      console.error("Error deleting template:", error);
      toast.error(`Failed to delete template: ${error.response?.data?.message || error.message}`);
    }
  };

   // Function to handle the actual deletion after confirmation
   const handleConfirmDelete = async (e) => {
    e.preventDefault();
    // Call the original handleDeleteTemplate function with the templateId
    await handleDeleteTemplate(deleteConfirmation.templateId);
    
    // Close the confirmation dialog
    setDeleteConfirmation({
      isOpen: false,
      templateId: null,
      templateName: ''
    });
  };
  
  // Function to cancel deletion
  const handleCancelDelete = (e) => {
    e.preventDefault();
    setDeleteConfirmation({
      isOpen: false,
      templateId: null,
      templateName: ''
    });
  };

  // const handleDeleteTemplate = async (templateId) => {
  //   // Show the confirmation dialog
  //   setTemplateToDelete(templateId);
  //   setShowDeleteConfirmation(true);
  // };
  
  // // Add at the beginning of component

  
  // // Add this function to actually perform the deletion
  // const confirmDeleteTemplate = async (e) => {
  //   e.preventDefault();
  
  //   try {
  //     const params = new URLSearchParams(window.location.search);
  //     const userId = params.get('userToken');
  //     const templateFormId = formData.responseUrl;
      
  //     // Find the template details for logging
  //     const templateToRemove = formData.formTemplates?.find(t => t.id === templateToDelete);
  //     console.log(`Deleting template: ${templateToRemove?.name} (ID: ${templateToDelete})`);
      
  //     // API call to delete the template
  //     await axios.delete(`${apiEndpoints.form}/template`, {
  //       params: {
  //         userId: userId,
  //         formId: templateFormId,
  //         templateId: templateToDelete
  //       }
  //     });
      
  //     // Update local state to remove the template
  //     setFormData(prev => ({
  //       ...prev,
  //       // Update formTemplates (API structure)
  //       formTemplates: prev.formTemplates.filter(t => t.id !== templateToDelete),
  //       settings: {
  //         ...prev.settings,
  //         // Update settings.templates (UI structure)
  //         templates: prev.settings.templates.filter(t => t.id !== templateToDelete),
  //         // Disable template functionality if no templates remain
  //         templateEnabled: prev.formTemplates.length > 1 ? 1 : 0
  //       }
  //     }));
      
  //     toast.success("Template deleted successfully!");
  //   } catch (error) {
  //     console.error("Error deleting template:", error);
  //     toast.error(`Failed to delete template: ${error.response?.data?.message || error.message}`);
  //   } finally {
  //     // Reset the confirmation state
  //     setShowDeleteConfirmation(false);
  //     setTemplateToDelete(null);
  //   }
  // };
  
 

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    
  
    
setLoading(true);

    try {
      const params = new URLSearchParams(window.location.search);
      const userId = params.get('userToken');
      const formId = params.get('formId');
   
      // const templateFormId = formData.responseUrl ;
      
      // Step 1: Process templates first if they exist
      // if (formData.settings?.templateEnabled === 1 && formData.settings?.templates?.length > 0) {
      //   const templatePromises = formData.settings.templates.map(template => {
      //     console.log('Template Content:', template.content); // Debugging log
      //     console.log('Template :', template); // Debugging log
      //     return axios.post(`${apiEndpoints.form}/template?userId=${userId}&formId=${templateFormId}`, {
      //       googleDocsUrl: template.url,
      //       content: template.content
      //     });
      //   });
    
      //   // Wait for all template API calls to complete
      //   await Promise.all(templatePromises);
      // }
  
      // Step 2: After templates are processed, update the form
      const submissionFields = formFields
        .sort((a, b) => a.originalIndex - b.originalIndex)
        .map(({ originalIndex, ...field }) => field);
  
      const updatedData = {
        ...formData,
        formId: formId,
        userId: userId,
        settings: {
          ...formData.settings,
          loginRequired: formData.settings?.loginRequired || 0,
          templateEnabled: formData.settings?.templateEnabled || 0,
          templateUrl: formData.settings?.templateUrl || '',
          signatureEnabled: formData.settings?.signatureEnabled || 0,
          signatureWidth: formData.settings?.signatureWidth || 400,
          signatureHeight: formData.settings?.signatureHeight || 200,
            gdprEnabled: formData.settings?.gdprEnabled || 0  // Add this line
        },
        jdata: submissionFields,
        status: formData?.status
      };
  
      await axios.put(apiEndpoints.form, updatedData);
      setSubmitted(true);
      toast.success('Form updated successfully!');
      // setTimeout(() => {
      //   window.location.href = 'https://bolofy.com/client/forms/view';
      // }, 1000);
    } catch (error) {  
  
      console.error('Error updating form:', error);
      toast.error('Failed to update form');
    } finally {
      setLoading(false);
    }
  };

  const handleFieldChange = (originalIndex, field, value) => {
    setFormFields(prevFields => 
      prevFields.map(fieldItem => 
        fieldItem.originalIndex === originalIndex
          ? { ...fieldItem, [field]: value }
          : fieldItem
      )
    );
  };

  const handleDeleteField = (originalIndex) => {
    setFormFields(prevFields => 
      prevFields.map(field => 
        field.originalIndex === originalIndex
          ? { ...field, isRemoved: true }
          : field
      )
    );
  };

  const activeFields = formFields.filter(field => !field.isRemoved);
  
  const handleFormMetaChange = (field, value) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <motion.div 
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity }}
          className="w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full"
        />
      </div>
    );
  }

  if (submitted) {
    return (
      <motion.div className="min-h-screen flex items-center justify-center p-4">
        <div className="w-full max-w-2xl bg-gradient-to-br from-cyan-50 via-white to-cyan-50 p-8 rounded-2xl shadow-xl">
          <div className="text-center">
            <CheckCircle className="w-20 h-20 mx-auto text-cyan-500 mb-6" />
            <h2 className="text-3xl font-bold text-gray-800 mb-4">Updated Successfully!</h2>
            {/* <motion.button
              onClick={() => setSubmitted(false)}
              className="mt-8 px-8 py-3 bg-gradient-to-r from-cyan-600 to-slate-600 text-white rounded-xl"
              whileHover={{ scale: 1.05 }}
            >
              <RefreshCw className="w-5 h-5 inline mr-2" />
              Make another update
            </motion.button> */}
          </div>
        </div>
      </motion.div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="space-y-8">
          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="bg-gradient-to-r from-cyan-600 to-slate-600 p-6">
              <div className="flex items-center space-x-4 mb-4">
                <motion.button
                  onClick={handleGoBack}
                  className="flex items-center gap-2 text-white hover:bg-white/10 rounded-lg px-3 py-2 transition-colors"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <ArrowLeft className="w-5 h-5" />
                  <span>Back</span>
                </motion.button>
              </div>
            
              <div className="flex items-center justify-between">
                {editMode.title ? (
                  <input
                    type="text"
                    value={formData.name || ''}
                    onChange={(e) => handleFormMetaChange('name', e.target.value)}
                    className="text-3xl font-bold text-white bg-transparent border-b border-white outline-none w-full"
                    onBlur={() => setEditMode({ ...editMode, title: false })}
                    autoFocus
                  />
                ) : (
                  <h1 
                    className="text-3xl font-bold text-white flex items-center gap-3 cursor-pointer"
                    onClick={() => setEditMode({ ...editMode, title: true })}
                  >
                    <FileText className="w-8 h-8" />
                    {formData.name}
                    <Edit className="w-5 h-5" />
                  </h1>
                )}
              </div>
              
              {editMode.description ? (
                <textarea
                  value={formData.description || ''}
                  onChange={(e) => handleFormMetaChange('description', e.target.value)}
                  className="mt-3 text-cyan-100 text-lg bg-transparent border-b border-white outline-none w-full"
                  onBlur={() => setEditMode({ ...editMode, description: false })}
                  autoFocus
                />
              ) : (
                <p 
                  className="mt-3 text-cyan-100 text-lg cursor-pointer"
                  onClick={() => setEditMode({ ...editMode, description: true })}
                >
                  {formData.description}
                  <Edit className="w-4 h-4 inline ml-2" />
                </p>
              )}
            </div>

            <form onSubmit={handleSubmit} className="p-6 space-y-4">
              <AnimatePresence>
                {activeFields.map((field, displayIndex) => (
                  <motion.div
                    key={field.originalIndex}
                    className="bg-white p-4 rounded-lg border hover:border-cyan-500 transition-all duration-200"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                  >
                    <div className="flex justify-between items-start mb-3">
                      <div className="flex items-center gap-2">
                        {editMode[`field-${field.originalIndex}`] ? (
                          <input
                            type="text"
                            value={field.label}
                            onChange={(e) => handleFieldChange(field.originalIndex, 'label', e.target.value)}
                            className="text-base font-semibold border-b border-gray-300 focus:border-cyan-500 outline-none"
                            onBlur={() => setEditMode({ ...editMode, [`field-${field.originalIndex}`]: false })}
                            autoFocus
                          />
                        ) : (
                          <div className="flex items-center gap-2">
                            <span className="text-base font-semibold">
                              {field.label}
                              {field.required && <span className="text-red-500 ml-1">*</span>}
                            </span>
                            <button
                              type="button"
                              onClick={() => setEditMode({ ...editMode, [`field-${field.originalIndex}`]: true })}
                              className="p-1 text-gray-500 hover:text-cyan-600 rounded-full hover:bg-gray-100 transition-colors"
                            >
                              <Edit className="w-3.5 h-3.5" />
                            </button>
                          </div>
                        )}
                      </div>

                      <div className="flex items-center gap-3">
                        <select
                          value={field.type || 'text'}
                          onChange={(e) => handleFieldChange(field.originalIndex, 'type', e.target.value)}
                          className="border rounded py-1 px-2 text-xs"
                        >
                          <option value="text">Text</option>
                          <option value="email">Email</option>
                          <option value="number">Number</option>
                          <option value="date">Date</option>
                          <option value="textarea">Textarea</option>
                        </select>
                        <label className="flex items-center gap-1 text-xs">
                          <input
                            type="checkbox"
                            checked={field.required}
                            onChange={(e) => handleFieldChange(field.originalIndex, 'required', e.target.checked)}
                            className="rounded border-gray-300 text-cyan-600 focus:ring-cyan-500 w-3 h-3"
                          />
                          Required
                        </label>
                        <button
                          type="button"
                          onClick={() => handleDeleteField(field.originalIndex)}
                          className="p-1 text-gray-500 hover:text-red-600 rounded-full hover:bg-gray-100 transition-colors"
                        >
                          <Trash2 className="w-3.5 h-3.5" />
                        </button>
                      </div>
                    </div>

                    <div className="flex items-center gap-2">
                      {editMode[`placeholder-${field.originalIndex}`] ? (
                        <input
                          type="text"
                          value={field.placeHolder}
                          onChange={(e) => handleFieldChange(field.originalIndex, 'placeHolder', e.target.value)}
                          className="text-sm p-1 border-b border-gray-300 focus:border-cyan-500 outline-none"
                          onBlur={() => setEditMode({ ...editMode, [`placeholder-${field.originalIndex}`]: false })}
                          autoFocus
                        />
                      ) : (
                        <div className="flex items-center gap-2 text-gray-500">
                          <span className="text-sm">{field.placeHolder}</span>
                          <button
                            type="button"
                            onClick={() => setEditMode({ ...editMode, [`placeholder-${field.originalIndex}`]: true })}
                            className="p-1 hover:text-cyan-600 rounded-full hover:bg-gray-100 transition-colors"
                          >
                            <Edit className="w-3.5 h-3.5" />
                          </button>
                        </div>
                      )}
                    </div>
                  </motion.div>
                ))}
              </AnimatePresence>

{/* login  */}
<motion.div 
  className="bg-gradient-to-br from-blue-50 to-cyan-50 p-6 rounded-xl shadow-md border border-blue-100 mt-8"
  initial={{ opacity: 0, y: 20 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ duration: 0.3 }}
>
  <div className="flex justify-between items-start">
    <div className="flex items-center gap-4">
      <div className="bg-blue-100 p-3 flex items-center h-fit rounded-full">
        <Lock className="w-6 h-6  text-blue-600" />
      </div>
      <div>
        <h4 className="text-lg font-semibold text-gray-900 mb-1">Login Protection</h4>
        <p className="text-gray-600 text-sm max-w-md">
          Enable login requirement to restrict form access to authorized users only. 
          When enabled, users must authenticate before accessing the form.
        </p>
        {formData.settings?.loginRequired === 1 && (
          <div className="mt-2 text-sm text-blue-600 flex items-center gap-2">
            <span className="inline-block w-2 h-2 bg-blue-600 rounded-full"></span>
            Currently active with {formData.formUsers?.length || 0} authorized users
          </div>
        )}
      </div>
    </div>
    
    <div className="flex flex-col items-end gap-2">
      <label className="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          checked={formData.settings?.loginRequired === 1}
          onChange={(e) => {
            setFormData(prev => ({
              ...prev,
              settings: {
                ...prev.settings,
                loginRequired: e.target.checked ? 1 : 0
              }
            }));
          }}
          className="sr-only peer"
        />
        <div className="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 
                      rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full 
                      peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] 
                      after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 
                      after:transition-all peer-checked:bg-blue-600"
        ></div>
      </label>
      <span className="text-xs text-gray-500">
        {formData.settings?.loginRequired === 1 ? 'Protected' : 'Public'}
      </span>
    </div>
  </div>
</motion.div>

{/* Template  */}
 {/* Template Section */}
 <motion.div 
                className="bg-gradient-to-br from-purple-50 to-pink-50 p-6 rounded-xl shadow-md border border-purple-100 mt-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                <div className="flex justify-between items-start">
                  <div className="flex items-center gap-4">
                    <div className="bg-purple-100 p-3 flex items-center h-fit rounded-full">
                      <FileText className="w-6 h-6 text-purple-600" />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-gray-900 mb-1">Document Templates</h4>
                      <p className="text-gray-600 text-sm max-w-md">
                        Add multiple document templates to automatically populate with form submission data.
                        Each template can be customized for different purposes.  Use <code>{"{{date}}"}</code> to automatically insert the current date in your document.
                      </p>

                      {/* Google Sign In Button */}
                      {formData.settings?.templateEnabled === 1 && (
  <div className="mt-3">
    {!isSignedIn ? (
                        <motion.button
                          onClick={handleSignIn}
                          className="mt-3 px-4 py-2 text-gray-800 bg-gradient-to-br from-purple-300 to-pink-300 rounded-md shadow-md hover:bg-pink-600 transition flex items-center gap-2"
                          whileHover={{ scale: 1.02 }}
                          whileTap={{ scale: 0.98 }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 48 48">
                          <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
                          </svg>
                          Sign in with Google
                        </motion.button>
                        ) : (
                          <motion.div 
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className=" mt-2 w-48 bg-white rounded-md shadow-lg z-10"
          >
            <button
              onClick={handleGoogleLogout}
              className="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-red-50 rounded-md flex items-center gap-2"
            >
              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
              </svg>
              Sign out from Google
            </button>
          </motion.div>  )}
          </div>
        )}

{formData.settings?.templateEnabled === 1 && isSignedIn && (
                      <div className="mt-4 space-y-3">
                        {formData.settings?.templateEnabled === 1 && (
                          <div className="space-y-2">
                            <div className="flex gap-2">
                              <input
                                type="text"
                                value={newTemplateName}
                                onChange={(e) => setNewTemplateName(e.target.value)}
                                placeholder="Template name"
                                className="w-1/3 px-3 py-2 border border-purple-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 text-sm"
                              />
                              <input
                                ref={inputRef}
                                type="url"
                                value={newTemplateUrl}
                                onChange={(e) => setNewTemplateUrl(e.target.value)}
                                placeholder="Enter Google Docs URL"
                                className="flex-1 px-3 py-2 border border-purple-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 text-sm"
                              />
                              <motion.button
                                type="button"
                                onClick={handleAddTemplate}
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                                disabled={fetchingDoc}
                                className="px-3 py-2 bg-purple-600 text-white rounded-md flex items-center gap-2 disabled:opacity-70"
                              >
                                {fetchingDoc ? (
                                  <motion.div
                                    animate={{ rotate: 360 }}
                                    transition={{ duration: 1, repeat: Infinity }}
                                    className="w-4 h-4 border-2 border-white border-t-transparent rounded-full"
                                  />
                                ) : (
                                  <Plus className="w-4 h-4" />
                                )}
                                Add
                              </motion.button>
                            </div>

                            <div className="space-y-2">
                              {(formData.settings?.templates || []).map((template) => (
                                <div key={template.id} className="flex items-center gap-2 bg-white p-2 rounded-md">
                                  {editMode[template.id] ? (
                                    <>
                                      <input
                                        type="text"
                                        value={template.name}
                                        onChange={(e) => {
                                          const updatedTemplates = formData.settings.templates.map(t =>
                                            t.id === template.id ? { ...t, name: e.target.value } : t
                                          );
                                          setFormData(prev => ({
                                            ...prev,
                                            settings: { ...prev.settings, templates: updatedTemplates }
                                          }));
                                        }}
                                        className="w-1/3 px-2 py-1 border border-purple-300 rounded focus:outline-none focus:ring-2 focus:ring-purple-500 text-sm"
                                        autoFocus
                                      />
                                         <input
                                           type="url"
                                           value={template.url}
                                           onChange={(e) => {
                                             const updatedTemplates = formData.settings.templates.map(t =>
                                               t.id === template.id ? { ...t, url: e.target.value } : t
                                             );
                                             setFormData(prev => ({
                                               ...prev,
                                               settings: { ...prev.settings, templates: updatedTemplates }
                                             }));
                                           }}
                                           className="flex-1 px-2 py-1 border border-purple-300 rounded focus:outline-none focus:ring-2 focus:ring-purple-500 text-sm"
                                         />
                                         <button
                                           type="button"
                                           onClick={() => handleEditTemplate(template)}
                                           className="p-1 hover:bg-green-100 rounded-full transition-colors"
                                           disabled={fetchingDoc}
                                         >
                                           {fetchingDoc ? (
                                             <motion.div
                                               animate={{ rotate: 360 }}
                                               transition={{ duration: 1, repeat: Infinity }}
                                               className="w-4 h-4 border-2 border-green-500 border-t-transparent rounded-full"
                                             />
                                           ) : (
                                             <Check className="w-4 h-4 text-green-600" />
                                           )}
                                         </button>
                                       </>
                                     ) : (
                                       <>
                                         <span className="w-1/3 text-sm font-medium text-gray-700">{template.name}</span>
                                         <a 
                                           href={template.url} 
                                           target="_blank" 
                                           rel="noopener noreferrer" 
                                           className="flex-1 text-sm text-blue-600 hover:text-blue-800 hover:underline truncate"
                                         >
                                           {template.url}
                                         </a>
                                         <div className="flex gap-1">
                                         <button
            type="button"
            onClick={() => {
              const documentId = extractDocumentId(template.url);
              if (documentId) {
                exportToPdf(documentId, `${template.name}.pdf`);
              } else {
                toast.error("Invalid document URL");
              }
            }}
            className="p-1 hover:bg-blue-100 rounded-full transition-colors"
            title="Export as PDF"
          >
             <FileText className="w-4 h-4 text-purple-600" />
             </button>
                                           <button
                                             type="button"
                                             onClick={() => setEditMode(prev => ({ ...prev, [template.id]: true }))}
                                             className="p-1 hover:bg-purple-100 rounded-full transition-colors"
                                           >
                                             <Edit className="w-4 h-4 text-purple-600" />
                                           </button>
                                            
      {/* Add the confirmation dialog component */}
      <DeleteConfirmationDialog
        isOpen={deleteConfirmation.isOpen}
        templateName={deleteConfirmation.templateName}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />
                                           <button
                                             type="button"
                                             onClick={() => confirmTemplateDelete(template)}
                                             className="p-1 hover:bg-red-100 rounded-full transition-colors"
                                           >
                                             <Trash2 className="w-4 h-4 text-red-600" />
                                           </button>
                                         </div>
                                       </>
                                     )}
                                   </div>
                                 ))}
                               </div>
                             </div>
                           )}
                         </div>
                         )}
                       </div>
                     </div>
                     
                     <div className="flex flex-col items-end gap-2">
                       <label className="relative inline-flex items-center cursor-pointer">
                         <input
                           type="checkbox"
                           checked={formData.settings?.templateEnabled === 1}
                           onChange={(e) => {
                             setFormData(prev => ({
                               ...prev,
                               settings: {
                                 ...prev.settings,
                                 templateEnabled: e.target.checked ? 1 : 0
                               }
                             }));
                           }}
                           className="sr-only peer"
                         />
                         <div className="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-purple-300 
                                       rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full 
                                       peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] 
                                       after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 
                                       after:transition-all peer-checked:bg-purple-600"
                         ></div>
                       </label>
                       <span className="text-xs text-gray-500">
                         {formData.settings?.templateEnabled === 1 ? 'Enabled' : 'Disabled'}
                       </span>
                     </div>
                   </div>
                 </motion.div>

{/* sign */}
{/* Digital Signature Section */}
<motion.div 
  className="bg-gradient-to-br from-green-50 to-emerald-50 p-6 rounded-xl shadow-md border border-green-100 mt-4"
  initial={{ opacity: 0, y: 20 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ duration: 0.3 }}
>
  <div className="flex justify-between items-start">
    <div className="flex items-center gap-4">
      <div className="bg-green-100 p-3 flex items-center h-fit rounded-full">
        <PenTool className="w-6 h-6 text-green-600" />
      </div>
      <div>
        <h4 className="text-lg font-semibold text-gray-900 mb-1">Digital Signature</h4>
        <p className="text-gray-600 text-sm max-w-md">
          Enable signature pad to collect digital signatures from form submissions.
          Users can draw their signature directly on the form.
        </p>
        
        {formData.settings?.signatureEnabled === 1 && (
          <div className="mt-4 grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Width (px)</label>
              <input
                type="number"
                value={formData.settings?.signatureWidth || 400}
                onChange={(e) => {
                  const value = parseInt(e.target.value) || 400;
                  setFormData(prev => ({
                    ...prev,
                    settings: {
                      ...prev.settings,
                      signatureWidth: value
                    }
                  }));
                }}
                min="200"
                max="800"
                className="w-full px-3 py-2 border border-green-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Height (px)</label>
              <input
                type="number"
                value={formData.settings?.signatureHeight || 200}
                onChange={(e) => {
                  const value = parseInt(e.target.value) || 200;
                  setFormData(prev => ({
                    ...prev,
                    settings: {
                      ...prev.settings,
                      signatureHeight: value
                    }
                  }));
                }}
                min="100"
                max="400"
                className="w-full px-3 py-2 border border-green-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 text-sm"
              />
            </div>
          </div>
        )}
      </div>
    </div>
    
    <div className="flex flex-col items-end gap-2">
      <label className="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          checked={formData.settings?.signatureEnabled === 1}
          onChange={(e) => {
            setFormData(prev => ({
              ...prev,
              settings: {
                ...prev.settings,
                signatureEnabled: e.target.checked ? 1 : 0,
                signatureWidth: prev.settings?.signatureWidth || 400,
                signatureHeight: prev.settings?.signatureHeight || 200
              }
            }));
          }}
          className="sr-only peer"
        />
        <div className="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-300 
                      rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full 
                      peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] 
                      after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 
                      after:transition-all peer-checked:bg-green-600"
        ></div>
      </label>
      <span className="text-xs text-gray-500">
        {formData.settings?.signatureEnabled === 1 ? 'Enabled' : 'Disabled'}
      </span>
    </div>
  </div>
</motion.div>

{/* GDPR Consent Banner */}
<motion.div 
  className="bg-gradient-to-br from-yellow-50 to-amber-50 p-6 rounded-xl shadow-md border border-yellow-100 mt-4"
  initial={{ opacity: 0, y: 20 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ duration: 0.3 }}
>
  <div className="flex justify-between items-start">
    <div className="flex items-center gap-4">
      <div className="bg-yellow-100 p-3 flex items-center h-fit rounded-full">
        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-yellow-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4" />
        </svg>
      </div>
      <div>
        <h4 className="text-lg font-semibold text-gray-900 mb-1">GDPR Consent Banner</h4>
        <p className="text-gray-600 text-sm max-w-md">
          Enable GDPR consent banner to collect user consent for cookies and data processing.
          This helps ensure compliance with privacy regulations like GDPR.
        </p>
        {formData.settings?.gdprEnabled === 1 && (
          <div className="mt-2 text-sm text-yellow-600 flex items-center gap-2">
            <span className="inline-block w-2 h-2 bg-yellow-600 rounded-full"></span>
            Visitors will be asked for cookie consent when accessing this form
          </div>
        )}
      </div>
    </div>
    
    <div className="flex flex-col items-end gap-2">
      <label className="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          checked={formData.settings?.gdprEnabled === 1}
          onChange={(e) => {
            setFormData(prev => ({
              ...prev,
              settings: {
                ...prev.settings,
                gdprEnabled: e.target.checked ? 1 : 0
              }
            }));
          }}
          className="sr-only peer"
        />
        <div className="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-yellow-300 
                      rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full 
                      peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] 
                      after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 
                      after:transition-all peer-checked:bg-yellow-600"
        ></div>
      </label>
      <span className="text-xs text-gray-500">
        {formData.settings?.gdprEnabled === 1 ? 'Enabled' : 'Disabled'}
      </span>
    </div>
  </div>
</motion.div>

               <div className="flex justify-between items-center pt-4 mt-8 border-t">
           <p className="text-rose-500 text-xs">* Required fields</p>
           <motion.button
             type="submit"
             className="px-6 py-3 bg-gradient-to-r from-cyan-600 to-slate-600 text-white rounded-lg text-sm font-medium shadow-sm hover:shadow-md transition-all"
             whileHover={{ scale: 1.02 }}
           >
             Update Form
             <ChevronRight className="w-4 h-4 inline ml-1" />
           </motion.button>
         </div>
       </form>


            {formData.settings?.loginRequired === 1 && (
       <div className="bg-gradient-to-r from-cyan-100 to-blue-50 rounded-xl p-6 shadow-lg">
         <div className="bg-white rounded-lg shadow-sm p-4 mt-0">
           <AddUser
             formId={new URLSearchParams(window.location.search).get('formId')}
             userId={new URLSearchParams(window.location.search).get('userToken')}
             formUsers={formData.formUsers || []}
           />
         </div>
       </div>
     )}
          </div>

          <FormResponsesTable 
            responses={formResponses} 
            formFields={activeFields}
            formData={formData}
          />
        </div>
      </div>
    </div>
  );
};

export default UpdateForm;