// Configuration constants

export const maxMessageLength = 1000;
export const maxRecordingDuration = 15;

export const apiEndpoints = {
  chat: "https://bolofy.com/api/chatBot/get-message",
  generateAudio: "https://bolofy.com/api/chatBot/get-message",
  leadCapture: "https://bolofy.com/api/botCallLog/bot-lead-capture",
  chatbot_config: "https://bolofy.com/api/chatBot",
  chatbot_config_events: "https://bolofy.com/api/chatBot/events/get-slots",
  feedback: "https://bolofy.com/api/feedback",
  appointment: "https://bolofy.com/api/chatBot/events/book-slot", 
  status: "https://bolofy.com/api/user/status",
  tasks: "https://bolofy.com/api/user/task", 
  task_update: "https://bolofy.com/api/user/task-update",
  form: "https://bolofy.com/api/form",
  formLogin: "https://bolofy.com/api/user/form/login",
  login: "https://bolofy.com/api/user/login",
  logout: "https://bolofy.com/api/user/logout",
};

export const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6Ikpva";
export const accessToken = "eyJpdiI6IlRYYW9QUndRYytSckZJRXhIUGRPTnc9PSIsInZhbHVlIjoiT3dUTFhPRkFtMUZUOXNENU5IbkZXdnFyNW9nakxKL3BtV3ZIRHJYbjlVZzZYNXg3blpPUmlLYU04cThNdHVSN1hpemE1SHFZNVpDdmdjcXVOZ1l0Z0kyd1hucWRiU1VxbkZVL3VtUGc3aXY0UHI5bzIwS3loWTNoQ2owUEYrMjAiLCJtYWMiOiI0MTk4NGM4YWVkNjk1NDAzNzMwZWIwZjczM2E2NDY2NTE4NDQ3NGRkOGQzNjQwYzE1MDU2N2FjODVhYzVmMDYxIiwidGFnIjoiIn0"
// export const formAuthToken = "eyJpdiI6ImpZZWVaa1lGdkl3R0J4cWx6MzZYcFE9PSIsInZhbHVlIjoiVjhqb1JFUEZLRVNLaExWb21UUlowampkcHBXWmZzTlNkNUV4NXQ0YzhlUnlTQ2kvYTZPODNrejluZHg1ZHJWT1dzNmZ4aGR0d0FveHVvbzlRQUx0VUYxS2tVSWNRaDVZeDBKQmJkRVlLNmRLSzFxUFdhUWJaTXRrSHVVVDlGV3p3eDdIcUp5TXI3Z2VnaWhqSDBPTStRPT0iLCJtYWMiOiIxNGQ0NWFiNjNhODE4NjAzYjM3NWMyMmQ0M2IwYTBmNDc0N2EwOWM3MWQyNTUwNWIyOTYyMTQ4OTNkN2UyZDBjIiwidGFnIjoiIn0=";

export const validationRegex = {
  name: /^[a-zA-Z\s]{2,50}$/,
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  phone: /^\+?[1-9]\d{1,14}$/
};

// New custom branding configuration
export const customBranding = {
  color: "#2888b5",
  primaryShadowColor: "#2888b581",
  secondaryColor: "#ffffff",
  warningColor: "#2312e81888b5", 
  sendColor: "#2888b5", 
  toggleStartColor: "#ffffff", 
  footerBtnBgColor: "#ffffff", 
  chatBgColor: "#ffffff", 
  headerTextColor: "#ffffff", 
  msgUserTextColor: "#2a4365", 
  msgBotTextColor: "#", 
  msgUserBgColor: "#dbeafe", 
  msgBotBgColor: "#e0f2fe", 
  audioUserPlayColor: "#368dff", 
  audioBotPlayColor: "#2888b5", 
  audioBotTimeTextColor: "#2888b5",
  audioUserTimeTextColor: "#368dff",
  audioBotTimeStampColor: "#7c838f",
  audioUserTimeStampColor: "#7c838f",
  audioBotTranscriptColor: "#2888b5",
  actionBgColor: "#ffffff",
  actionTextColor: "#39424f",
  SpectrumAnimation: "#3856b8",
  LoadingSpinner: "#3f75ed",
  scrollbarColor: "#2888b5", // Scrollbar color
  scrollbarTrackColor: "#DFE9EB",  // Scrollbar track color
  name: "Bolofy",
  logo_url: "assets/bolofy_round.webp",
};