import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Loader2, KeyRound } from 'lucide-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { apiEndpoints } from '../config/config';

const FormLogin = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [formId, setFormId] = useState(null);

  useEffect(() => {
    // Extract formId from the redirect URL
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrl = urlParams.get('redirect');
    
    if (redirectUrl) {
      try {
        const url = new URL(decodeURIComponent(redirectUrl));
        const pathSegments = url.pathname.split('/');
        const id = pathSegments[pathSegments.length - 1];
        setFormId(id);
      } catch (error) {
        console.error('Error parsing redirect URL:', error);
      }
    }

    // Check if user is already logged in
    const existingToken = localStorage.getItem('formUserToken');
    if (existingToken && redirectUrl) {
      // Validate token before redirecting
      window.location.href = decodeURIComponent(redirectUrl);
    }
  }, []);


  const validateForm = () => {
    const newErrors = {};
    if (!formData.email) newErrors.email = 'Email is required';
    else if (!emailRegex.test(formData.email)) newErrors.email = 'Please enter a valid email';
    if (!formData.password) newErrors.password = 'Password is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setSubmitting(true);
    try {
      // Add formId to login request if available
      // const loginData = {
      //   ...formData,
      //   formId: formId // Include formId in login request if needed
      // };

      const response = await axios.post(
      apiEndpoints.formLogin, // Update this to match your actual API endpoint
      {
        email: formData.email,
        password: formData.password,
        formId: formId
      },
        {
          headers: {
            'clientToken': `Bearer ${formId}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        }
      );

      if (response.status === 200 && response.data.token) {
        localStorage.setItem('formUserToken', response.data.token);
        toast.success('Login successful!');
        
      
        const redirectUrl = new URLSearchParams(window.location.search).get('redirect');
        window.location.href = redirectUrl ? decodeURIComponent(redirectUrl) : '/';
      }
      
    } catch (error) {
      console.error('Login error:', error);
      let errorMessage = 'Login failed';
      
      if (error.response) {
        // Handle specific error messages from backend
        errorMessage = error.response.data.message || 'Invalid credentials';
      } else if (error.request) {
        errorMessage = 'Network error. Please try again.';
      }
      
      toast.error(errorMessage);
    } finally {
      setSubmitting(false);
    }
  };

  // Email regex for validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-md w-full bg-white rounded-2xl shadow-xl overflow-hidden"
      >
        <div className="bg-gradient-to-r from-cyan-600 to-slate-600 p-6">
          <div className="flex items-center gap-3 mb-2">
            <KeyRound className="w-8 h-8 text-white" />
            <h1 className="text-2xl font-bold text-white">Form Login</h1>
          </div>
          <p className="text-cyan-100">Please login to access the form</p>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              value={formData.email}
              onChange={(e) => {
                setFormData({ ...formData, email: e.target.value });
                if (errors.email) setErrors({ ...errors, email: '' });
              }}
              className={`mt-1 block w-full rounded-md shadow-sm focus:ring-cyan-500 focus:border-cyan-500 ${
                errors.email ? 'border-red-500' : 'border-gray-300'
              }`}
            />
            {errors.email && (
              <p className="mt-1 text-sm text-red-500">{errors.email}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Password</label>
            <input
              type="password"
              value={formData.password}
              onChange={(e) => {
                setFormData({ ...formData, password: e.target.value });
                if (errors.password) setErrors({ ...errors, password: '' });
              }}
              className={`mt-1 block w-full rounded-md shadow-sm focus:ring-cyan-500 focus:border-cyan-500 ${
                errors.password ? 'border-red-500' : 'border-gray-300'
              }`}
            />
            {errors.password && (
              <p className="mt-1 text-sm text-red-500">{errors.password}</p>
            )}
          </div>

          <motion.button
            type="submit"
            disabled={submitting}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-cyan-600 to-slate-600 hover:from-cyan-700 hover:to-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 disabled:opacity-50"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            {submitting ? (
              <>
                <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                Logging in...
              </>
            ) : (
              'Login'
            )}
          </motion.button>
        </form>
      </motion.div>
    </div>
  );
};

export default FormLogin;