import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { format } from "date-fns";
import * as RiIcons from "react-icons/ri";
import * as FaIcons from "react-icons/fa";
import SpectrumAnimation from "../Animation/SpectrumAnimation";

const MessageContent = ({
  message,
  config,
  customBranding,
  toggleAudioPlayback,
  audioPlaying,
  handleToggleSpeech,
  playingMessageId,
  maxLength = 150,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const renderExpandableText = (text) => {
    // Add null check for text
    if (!text) return "";

    const needsExpansion = text.length > maxLength;
    const truncatedText =
      needsExpansion && !isExpanded ? text.slice(0, maxLength) + "..." : text;

    return (
      <div className="relative">
        <AnimatePresence mode="wait">
          <motion.div
            key={isExpanded ? "expanded" : "collapsed"}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="whitespace-pre-line text-justify hyphens-auto"
          >
            {truncatedText}
          </motion.div>
        </AnimatePresence>

        {needsExpansion && (
          <div className="flex justify-center w-full">
            <motion.button
              onClick={() => setIsExpanded(!isExpanded)}
              className="flex justify-center items-center w-8 h-8 rounded-full hover:bg-black/5 transition-colors"
              style={{ color: config.color }}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <motion.div
                animate={{ rotate: isExpanded ? 180 : 0 }}
                transition={{ duration: 0.2 }}
              >
                <FaIcons.FaChevronDown className="w-4 h-4" />
              </motion.div>
            </motion.button>
          </div>
        )}
      </div>
    );
  };

  if (message.isAudio) {
    return (
      <div className="flex flex-col">
        <div className="flex items-center">
          {message.type === "user" ? (
            <button
              onClick={() => toggleAudioPlayback(message.audioUrl)}
              className="mr-2 p-2 text-white rounded-full focus:outline-none focus:ring-2 focus:ring-yellow-400"
              style={{ backgroundColor: customBranding.audioUserPlayColor }}
            >
              {audioPlaying === message.audioUrl ? (
                <RiIcons.RiPauseFill size={20} />
              ) : (
                <RiIcons.RiPlayFill size={20} />
              )}
            </button>
          ) : (
            <button
              onClick={() => handleToggleSpeech(message.text || "", message.id)}
              className="mr-2 p-2 text-white rounded-full focus:outline-none focus:ring-2 focus:ring-yellow-400"
              style={{ backgroundColor: customBranding.audioBotPlayColor }}
            >
              {playingMessageId === message.id ? (
                <RiIcons.RiPauseFill size={20} />
              ) : (
                <RiIcons.RiPlayFill size={20} />
              )}
            </button>
          )}
          <span>{message.content}</span>
        </div>
        <div className="flex justify-between text-xs mt-1">
          <div
            style={{
              color:
                message.type === "bot"
                  ? customBranding.audioBotTimeTextColor
                  : customBranding.audioUserTimeTextColor,
            }}
          >
            {message.audioDuration}
          </div>
          <div
            style={{
              color:
                message.type === "bot"
                  ? customBranding.audioBotTimeStampColor
                  : customBranding.audioUserTimeStampColor,
            }}
          >
            {format(new Date(message.timestamp), "hh:mm a")}
          </div>
        </div>
        <div style={{ color: customBranding.audioBotTranscriptColor }}>
          {renderExpandableText(message.text)}
        </div>
      </div>
    );
  }

  if (message.isRecording) {
    return (
      <div className="flex flex-col items-start space-y-1">
        <div className="flex items-center space-x-2">
          <SpectrumAnimation />
          <span>{message.content || ""}</span>
        </div>
      </div>
    );
  }

  return renderExpandableText(message.content);
};

export default MessageContent;