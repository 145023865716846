import React from "react";
import { motion } from "framer-motion";
import AnimatedButtonLayout from "../Animation/AnimatedButtonLayout";

const InitialScreen = ({ customBranding, config, handleTalkClick, handleChatClick }) => {
  return (
    <div
      className="flex flex-col h-screen"
      style={{
        backgroundColor: customBranding.footerBtnBgColor,
      }}
    >
      <div
        className="flex-grow"
        style={{
          backgroundImage: `linear-gradient(to top, ${customBranding.secondaryColor}, ${config.color})`,
        }}
      >
        <div className="flex-grow flex relative z-10 items-center justify-center mt-16">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
            className="w-32 h-32 rounded-full bg-white shadow-lg overflow-hidden"
          >
            <img
              src={config.logo_url}
              alt={`${customBranding.name} Logo`}
              className="w-full h-full object-contain"
              style={{ scale: 1.05 }}
            />
          </motion.div>
        </div>
      </div>
      <div className="px-4 pt-4 pb-2 m-2">
        <AnimatedButtonLayout
          handleTalkClick={handleTalkClick}
          handleChatClick={handleChatClick}
        />
      </div>
    </div>
  );
};

export default InitialScreen;