import React from "react";

const VoiceBotFooter = ({ config }) => {
  return (
    <div className="p-2 bg-white text-gray-400 text-center text-xs rounded-b-lg">
      Powered by{" "}
      <span style={{ color: config.color }}>{`${config.name} `}</span>
    </div>
  );
};

export default VoiceBotFooter;