import React, { useEffect } from "react";
import "./index.css";
import "core-js/stable";
import "regenerator-runtime/runtime";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import VoiceBot from "./pages/VoiceBot";
// import VoiceBot from "./components/VoiceBot/VoiceBot";
import FormGenerator from "./SheetForm/FormGenerator";
import UpdateForm from "./SheetForm/UpdateForm";
import UserForm from "./SheetForm/UserForm";
import FormLogin from "./SheetForm/FormLogin";
// import GoogleDocsFetcher from "./SheetForm/GoogleDocsFetcher";
// import Sun from './SheetForm/sun';
// import VoiceBot from './components/VoiceBot'; // Adjust the path to your component
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { ThemeProvider } from './contexts/ThemeContext';
import { ChatbotConfigProvider } from "./contexts/ChatbotConfigContext";
import { customBranding } from "./config/config";
import { requestFCMToken, onMessageListener } from "./firebase";


const App = () => {
    React.useEffect(() => {
        // Set the CSS variables for primary color and scrollbar color dynamically

        document.documentElement.style.setProperty(
            "--scrollbar-color",
            customBranding.scrollbarColor
        );
        document.documentElement.style.setProperty(
            "--scrollbar-track-color",
            customBranding.scrollbarTrackColor
        );
    }, []);


    useEffect(() => {
        // Get the FCM Token
        requestFCMToken().then((token) => {
          if (token) {
            console.log("FCM Token:", token);
            // Send this token to your backend for push notifications
          }
        });
    
        // Listen for foreground messages
        onMessageListener()
          .then((payload) => {
            console.log("New notification:", payload);
            alert(`New Notification: ${payload.notification.title}`);
          })
          .catch((err) => console.log("Message Listener Error:", err));
      }, []);
    
    return (
        <BrowserRouter>
            <ChatbotConfigProvider>
                <Routes>
                    <Route path="/" element={<VoiceBot />} />
                    <Route path="/form-generator" element={<FormGenerator />} />
                    <Route path="/form-update" element={<UpdateForm />} />
                    <Route path="/form/:formId" element={<UserForm />} />
                    <Route path="/form-login" element={<FormLogin />} />
                    {/* <Route
                        path="/GoogleDocsFetcher"
                        element={<GoogleDocsFetcher />}
                    /> */}
                    {/* <Route path="/sun" element={<Sun />} /> */}
                </Routes>

                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </ChatbotConfigProvider>
        </BrowserRouter>
    );
};

export default App;
