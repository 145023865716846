import React, { Suspense } from "react";
import { motion, AnimatePresence } from "framer-motion";
import LoadingSpinner from "../Animation/LoadingSpinner";
import MessageContent from "../VoiceBot/MessageContent";

const Talk = React.lazy(() => import("../Talk/Talk"));
const Chat = React.lazy(() => import("../Chat/Chat"));

const ChatContainer = ({
  chatBoxRef,
  customBranding,
  messages,
  isTyping,
  isWaitingForResponse,
  config,
  toggleAudioPlayback,
  audioPlaying,
  handleToggleSpeech,
  playingMessageId,
  activeSection,
  setMessages,
  recordingState,
  setRecordingState,
  recordingDuration,
  setRecordingDuration,
  speechDetected,
  setSpeechDetected,
  setIsTyping,
  setIsLoading,
  setAbortController,
  playAudioAutomatically,
  showContactForm,
  setShowContactForm,
  showStatusForm,
  setShowStatusForm,
  showFeedbackForm,
  setShowFeedbackForm,
  showLoginForm,
  setShowLoginForm,
  currentChatId,
  isLoggedIn,
  setIsLoggedIn,
  setIsWaitingForResponse
}) => {
  return (
    <>
      {/* Chat messages area */}
      <div
        className="flex-1 overflow-y-auto overflow-x-hidden p-4 space-y-4"
        style={{
          backgroundColor: customBranding.chatBgColor,
        }}
        ref={chatBoxRef}
      >
        <AnimatePresence>
          {messages.map((message, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, scale: 0.8, y: 50 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.8, y: -50 }}
              transition={{ type: "spring", stiffness: 100 }}
              className={`flex ${
                message.type === "user" ? "justify-end" : "justify-start"
              }`}
            >
              <div
                className={`flex items-start ${
                  message.type === "user" ? null : "flex-row"
                } max-w-[80%] sm:max-w-[70%]`}
              >
                {message.type === "user" ? null : (
                  <div className="w-8 h-8 rounded-full flex-shrink-0 flex items-center justify-center mr-2">
                    <img
                      src={config.logo_url}
                      alt={`${config.name} Logo`}
                      className="w-8 h-8 object-contain rounded-full"
                    />
                  </div>
                )}
                <div
                  className={`p-3 mt-10 -ml-10 rounded-2xl shadow-md break-words text-sm sm:text-base`}
                  style={{
                    backgroundColor:
                      message.type === "user"
                        ? customBranding.msgUserBgColor
                        : customBranding.msgBotBgColor,
                    color:
                      message.type === "user"
                        ? customBranding.msgUserTextColor
                        : customBranding.msgBotTextColor,
                  }}
                >
                  <MessageContent
                    message={message}
                    config={config}
                    customBranding={customBranding}
                    toggleAudioPlayback={toggleAudioPlayback}
                    audioPlaying={audioPlaying}
                    handleToggleSpeech={handleToggleSpeech}
                    playingMessageId={playingMessageId}
                  />
                </div>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
        {(isTyping || isWaitingForResponse) && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="flex items-center text-gray-500 dark:text-gray-300"
          >
            <img
              src={config.logo_url}
              alt="Bolofy Logo"
              className="w-8 h-8 mr-3 object-contain rounded-full"
            />
            <LoadingSpinner />
          </motion.div>
        )}
      </div>
      {/* Input area */}
      <div
        className="p-2 shadow-top"
        style={{
          backgroundColor: customBranding.chatBgColor,
        }}
      >
        <Suspense fallback={<div>Loading...</div>}>
          {activeSection === "talk" && (
            <Talk
              setMessages={setMessages}
              isWaitingForResponse={isWaitingForResponse}
              setIsWaitingForResponse={setIsWaitingForResponse}
              recordingState={recordingState}
              setRecordingState={setRecordingState}
              recordingDuration={recordingDuration}
              setRecordingDuration={setRecordingDuration}
              speechDetected={speechDetected}
              setSpeechDetected={setSpeechDetected}
              setIsTyping={setIsTyping}
              setIsLoading={setIsLoading}
              setAbortController={setAbortController}
              playAudioAutomatically={playAudioAutomatically}
              playingMessageId={playingMessageId}
              handleToggleSpeech={handleToggleSpeech}
            />
          )}
          {activeSection === "chat" && (
            <Chat
              setMessages={setMessages}
              isWaitingForResponse={isWaitingForResponse}
              setIsWaitingForResponse={setIsWaitingForResponse}
              setIsTyping={setIsTyping}
              setAbortController={setAbortController}
              showContactForm={showContactForm}
              setShowContactForm={setShowContactForm}
              showStatusForm={showStatusForm}
              setShowStatusForm={setShowStatusForm}
              showFeedbackForm={showFeedbackForm}
              setShowFeedbackForm={setShowFeedbackForm}
              showLoginForm={showLoginForm}
              setShowLoginForm={setShowLoginForm}
              currentChatId={currentChatId}
              isLoggedIn={isLoggedIn}
              setIsLoggedIn={setIsLoggedIn}
            />
          )}
        </Suspense>
      </div>
    </>
  );
};

export default ChatContainer;