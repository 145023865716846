import React from "react";
import { motion } from "framer-motion";
import { useChatbotConfig } from "../../contexts/ChatbotConfigContext";

const LoadingSpinner = () => {
  const { config } = useChatbotConfig();

  const containerVariants = {
    animate: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const dotVariants = {
    initial: { y: 0 },
    animate: {
      y: [0, -10, 0],
      transition: {
        repeat: Infinity,
        duration: 1,
      },
    },
  };

  return (
    <motion.div
      className="flex space-x-2"
      variants={containerVariants}
      initial="initial"
      animate="animate"
    >
      {[...Array(3)].map((_, i) => (
        <motion.div
          key={i}
          className={`h-2 w-2 rounded-full`}
          style={{ backgroundColor: config.color }}
          variants={dotVariants}
          custom={i}
        />
      ))}
    </motion.div>
  );
};

export default LoadingSpinner;